import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../api';

export default function InputSelectWithoutRadio({
    name,
    value,
    onChange,
    label,
    options = [],
    showDelete,
    onDelete,
    error,
    placeholder = 'Select an option',
    disabled = false,
    company = null,
    billingPeriod,
    onBillingPeriodChange,
}) {
    const handlerChange = useCallback(
        (e) => {
            console.log(`InputSelectWithoutRadio (${name}): Selected value:`, e.target.value);
            onChange(e);
        },
        [name, onChange]
    );

    const handleDelete = () => {
        console.log(`InputSelectWithoutRadio (${name}): Delete button clicked`);
        onDelete();
    };

    const [planData, setPlanData] = useState(null);

    useEffect(() => {
        const fetchPlanData = async () => {
            try {
                if (company && company.active_plans && company.active_plans.length > 0) {
                    const activeProPlan = company.active_plans.find(
                        (planObj) =>
                            planObj.plan &&
                            planObj.plan.name === 'Pro' &&
                            planObj.subscription_status === 'active'
                    );

                    if (activeProPlan && activeProPlan.plan) {
                        setPlanData({
                            billing_period: activeProPlan.billing_period || 'monthly',
                            monthly_price: activeProPlan.plan.monthly_price,
                            annual_price: activeProPlan.plan.annual_price,
                        });
                    } else {
                        // Если нет активного плана, получаем данные плана Pro с сервера
                        console.log('Fetching Pro plan from server...');
                        const response = await api.get('/plans/pro/');
                        setPlanData({
                            monthly_price: response.data.monthly_price,
                            annual_price: response.data.annual_price,
                        });
                        console.log('Pro plan fetched:', response.data);
                    }
                } else {
                    // Если компания не выбрана или нет активных планов, получаем данные плана Pro с сервера
                    console.log('Fetching Pro plan from server...');
                    const response = await api.get('/plans/pro/');
                    setPlanData({
                        monthly_price: response.data.monthly_price,
                        annual_price: response.data.annual_price,
                    });
                    console.log('Pro plan fetched:', response.data);
                }
            } catch (error) {
                console.error('Error fetching Pro plan:', error);
                setPlanData(null);
            }
        };

        fetchPlanData();
    }, [company]);

    const handleBillingToggle = () => {
        if (!disabled) {
            const newBillingPeriod = billingPeriod === 'monthly' ? 'annually' : 'monthly';
            console.log(`Billing period changed to ${newBillingPeriod}`);

            // Вызываем callback, чтобы обновить billingPeriod в родительском компоненте
            if (onBillingPeriodChange) {
                onBillingPeriodChange(newBillingPeriod);
            }
        }
    };

    return (
        <>
            <label className="input-label">
                <select
                    name={name}
                    value={value}
                    onChange={handlerChange}
                    className={`input ${value ? 'filled' : ''}`}
                    style={{ paddingTop: 0 }}
                    disabled={disabled}
                >
                    <option value="" disabled>
                        {label || placeholder}
                    </option>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                {showDelete && (
                    <div className="help js--show-info" data-text="Add Icon">
                        <button
                            type="button"
                            className="button no-style delete-button"
                            onClick={handleDelete}
                            style={{ backgroundColor: 'transparent' }}
                            disabled={disabled}
                        >
                            <img src="/assets/icon--delete-gray.svg" alt="Delete" />
                        </button>
                    </div>
                )}
                {error && <span className="error-message">{error}</span>}
            </label>
            {planData && (
                <div className="billing-toggle-container">
                    <div className="billing-toggle">
                        <span
                            className={`billing-text ${
                                billingPeriod === 'monthly' ? 'active' : 'inactive'
                            }`}
                        >
                            Monthly
                        </span>
                        <div
                            className={`toggle ${billingPeriod === 'annually' ? 'checked' : ''}`}
                            onClick={!disabled ? handleBillingToggle : null}
                            role="switch"
                            aria-checked={billingPeriod === 'annually'}
                            tabIndex={disabled ? -1 : 0}
                            onKeyPress={(e) => {
                                if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
                                    handleBillingToggle();
                                }
                            }}
                            style={{
                                cursor: disabled ? 'not-allowed' : 'pointer',
                                opacity: disabled ? 0.5 : 1,
                            }}
                        >
                            <div className="toggle__container">
                                <div className="toggle__circle"></div>
                            </div>
                        </div>
                        <span
                            className={`billing-text ${
                                billingPeriod === 'annually' ? 'active' : 'inactive'
                            }`}
                        >
                            Annually
                        </span>
                    </div>
                    <div className="billing-prices">
                        {billingPeriod === 'monthly' ? (
                            <span className="billing-price active">
                                ${planData.monthly_price}/month
                            </span>
                        ) : (
                            <>
                                <span className="billing-price inactive">
                                    ${planData.monthly_price}
                                </span>
                                <span className="billing-price active">
                                    ${(planData.annual_price / 12).toFixed(2)}/month
                                </span>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
