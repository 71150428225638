// src/components/User/SignUp.js

import React, {useState, useEffect} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import {useNotification} from '../../context/NotificationContext'; // Импортируем хук для уведомлений
import {useUser} from '../../context/UserContext'; // Импортируем хук контекста пользователя
import './SignIn.css'; // Используем тот же CSS файл, можно создать отдельный

const SignUp = () => {
    // Состояние формы, включая согласие с условиями
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreeToTerms: false // Добавляем состояние для чекбокса
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false); // Состояние загрузки
    const [createVendor, setCreateVendor] = useState(false);
    const {showNotification} = useNotification(); // Получаем функцию showNotification из контекста уведомлений
    const {setAuthTokens, setCreateVendorFlag} = useUser(); // Получаем функцию setAuthTokens из контекста пользователя
    const navigate = useNavigate();

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile'); // Перенаправление на профиль, если пользователь аутентифицирован
        }
    }, [navigate]);
    const handleCreateVendorToggle = () => {
        setCreateVendor((prev) => !prev);
    };

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value // Обработка чекбокса
        });
    };

    // Функция валидации формы
    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required.';
        }

        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required.';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid.';
        }

        if (!formData.password) {
            newErrors.password = 'Password is required.';
        } else if (formData.password.length < 8) {
            newErrors.password = 'Password must be at least 8 characters.';
        }

        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password.';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        if (!formData.agreeToTerms) {
            newErrors.agreeToTerms = 'You must agree to the terms of service and privacy policy.';
        }

        return newErrors;
    };

    // Обработчик отправки формы
    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            // Собираем все сообщения об ошибках
            const errorMessages = Object.values(validationErrors).join(' ');

            // Отображаем уведомление об ошибке
            showNotification(
                'Please correct the errors in the form.',
                errorMessages, // Передаем собранные сообщения об ошибках
                null,
                'error'
            );

            return;
        }

        setLoading(true);

        try {
            const emailLower = formData.email.toLowerCase();

            console.log('Sending data:', {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: emailLower,
                password: formData.password,
                confirm_password: formData.confirmPassword
            });

            const response = await api.post('/register/', {
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: emailLower,
                password: formData.password,
                confirm_password: formData.confirmPassword
            });

            if (response.status === 201) {
                showNotification(
                    'Your account has been created successfully.',
                    'Please check your email to verify your account.',
                    null,
                    'success'
                );

                // Автоматический вход пользователя после успешной регистрации
                try {
                    const loginResponse = await api.post('/token/', {
                        username: emailLower,
                        password: formData.password
                    });

                    if (loginResponse.status === 200) {
                        const tokens = {
                            access: loginResponse.data.access,
                            refresh: loginResponse.data.refresh
                        };

                        setAuthTokens(tokens);
                        localStorage.setItem('access_token', tokens.access);
                        localStorage.setItem('refresh_token', tokens.refresh);
                        if (createVendor) {
                            setCreateVendorFlag();
                        }

                        navigate('/profile');
                    } else {
                        showNotification(
                            'Login failed after registration. Please sign in manually.',
                            null,
                            null,
                            'error'
                        );

                        setTimeout(() => {
                            navigate('/sign-in');
                        }, 5000);
                    }
                } catch (loginError) {
                    console.log('Login error:', loginError.response);
                    showNotification(
                        'Automatic login failed. Please sign in manually.',
                        null,
                        null,
                        'error'
                    );

                    setTimeout(() => {
                        navigate('/sign-in');
                    }, 5000);
                }
            } else {
                setErrors({
                    email: response.data.detail || 'Registration failed.',
                    password: ' '
                });

                showNotification(
                    'Registration failed. Please try again.',
                    null,
                    null,
                    'error'
                );
            }
        } catch (error) {
            console.log('Error response:', error.response);
            if (error.response && error.response.data) {
                // Отображаем ошибку из API
                const errorMessage = error.response.data.error || 'An error occurred during registration.';
                showNotification(
                    'Registration Error',
                    errorMessage,
                    null,
                    'error'
                );

                setErrors(error.response.data);
            } else {
                showNotification(
                    'Something went wrong. Please try again later.',
                    'Unable to connect to the server.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            {/* Рендеринг уведомления */}
            <div className="main-form-container">
                <div
                    className="main-form__img"
                    style={{
                        backgroundColor: '#f5f5f5',
                        backgroundImage: "url('/assets/sign-in-image.png')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                        backgroundSize: 'cover',
                    }}
                ></div>

                <div className="main-form__right">
                    <form className="main-form__content" onSubmit={handleSubmit}>
                        <div className="scroll-wrap">
                            <h2>Create Account</h2>
                            {/* Добавляем ссылку "Already have an account? Sign In" */}
                            <div className="sign-up-link">
                                <span>Already have an account? </span>
                                <Link to="/sign-in">Sign In</Link>
                            </div>

                            <InputWithLabel
                                label='First Name'
                                name='firstName'
                                value={formData.firstName}
                                onChange={handleChange}
                                type='text'
                                hasError={!!errors.firstName} // Передаем hasError вместо error
                            />

                            <InputWithLabel
                                label='Last Name'
                                name='lastName'
                                value={formData.lastName}
                                onChange={handleChange}
                                type='text'
                                hasError={!!errors.lastName} // Передаем hasError вместо error
                            />

                            <InputWithLabel
                                label='E-mail'
                                name='email'
                                value={formData.email}
                                onChange={handleChange}
                                type='email'
                                hasError={!!errors.email} // Передаем hasError вместо error
                            />

                            <InputWithLabel
                                label='Password'
                                name='password'
                                value={formData.password}
                                onChange={handleChange}
                                type='password'
                                hasError={!!errors.password} // Передаем hasError вместо error
                            />

                            <InputWithLabel
                                label='Confirm Password'
                                name='confirmPassword'
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                type='password'
                                hasError={!!errors.confirmPassword} // Передаем hasError вместо error
                            />

                            <div className="checkbox-container">
                                <label className="label-checkbox">
                                    <input
                                        type="checkbox"
                                        name="agreeToTerms"
                                        checked={formData.agreeToTerms}
                                        onChange={handleChange}
                                    />
                                    <div className="psevdocheckbox"></div>
                                </label>
                                <span className="text-left">
                                    By signing up you agree to our
                                    <a href="/terms-of-service" className="checkbox__link" target="_blank"
                                       rel="noopener noreferrer"> Terms of Service</a> and
                                    <a href="/privacy-policy" className="checkbox__link" target="_blank"
                                       rel="noopener noreferrer"> Privacy Policy</a>.
                                </span>
                            </div>
                            {/* Добавляем кнопку "Create Vendor" перед кнопкой "Sign Up" */}
                            <div className='registration-toggle'>
                                <label
                                    onClick={handleCreateVendorToggle}
                                    className={createVendor ? 'toggle checked' : 'toggle'}
                                >
                                    <div className='toggle__container'>
                                        <div className='toggle__circle'></div>
                                    </div>
                                    <span>Registrate a Vendor</span>

                                </label>
                            </div>
                            <div className='registration-toggle'>
                                <label
                                    onClick={handleCreateVendorToggle}
                                    className={createVendor ? 'toggle checked' : 'toggle'}
                                >
                                    <div className='toggle__container'>
                                        <div className='toggle__circle'></div>
                                    </div>
                                    <span>Registrate a Law Firm</span>

                                </label>
                            </div>
                            <button className="button button-black" type="submit" disabled={loading}>
                                {loading ? 'Registering...' : 'Sign Up'}
                            </button>
                            <button className="button button-border">
                                <img src="/assets/Apple.svg" alt="Apple Icon" style={{marginRight: '8px'}}/>
                                Continue with Apple
                            </button>
                            <button className="button button-border">
                                <img src="/assets/Google.svg" alt="Google Icon" style={{marginRight: '8px'}}/>
                                Continue with Google
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SignUp;
