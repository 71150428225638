import React, {useState, useEffect} from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import WorkingHoursDisplay from "../Form/WorkingHoursDisplay"; // Импортируем новый компонент
import WorkingHoursEditor from "../Form/WorkingHoursEditor"; // Импортируем компонент редактора
import api from '../../api';

const EditProfilePopup = ({show, closeEditProfilePopup, initialData, onSave, isAttorney = false}) => {
    const currentYear = new Date().getFullYear();

    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        foundation_year: '',
        phone_number: '',
        web_site: '',
        description: '',
        social_media: initialData?.social_media || [],
        additional_phones: initialData?.additional_phones || [],
        address: {
            country_id: '',
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        free_consultation: initialData?.free_consultation || false,
        working_hours: initialData && !isAttorney && Array.isArray(initialData.working_hours)
            ? initialData.working_hours.map(wh => ({
                working_hours_id: wh.working_hours_id || wh.working_hours?.working_hours_id,
                day_of_week: wh.day_of_week || wh.working_hours?.day_of_week,
                start_time: wh.start_time || wh.working_hours?.start_time,
                end_time: wh.end_time || wh.working_hours?.end_time,
            }))
            : [],
    });

    const [platforms, setPlatforms] = useState([]);
    const [contactMethods, setContactMethods] = useState([]);
    const [showAddSocialPopup, setShowAddSocialPopup] = useState(false);
    const [newSocialMedia, setNewSocialMedia] = useState({platform_id: '', url: ''});
    const [showAddPhonePopup, setShowAddPhonePopup] = useState(false);
    const [newPhone, setNewPhone] = useState({phone_type_id: '', phone_number: ''});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    // Состояния для модального окна управления рабочими часами
    const [showEditWorkingHoursPopup, setShowEditWorkingHoursPopup] = useState(false);

    // Обновление formData при изменении workingHours
    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            working_hours: formData.working_hours || []
        }));
    }, [formData.working_hours]);

    // Функции для работы с адресом
    const handleAddressChange = async (name, value) => {
        let parsedValue = value;

        if (['state_id', 'city_id', 'zip_code_id', 'country_id'].includes(name)) {
            parsedValue = Number(value);
        }

        setFormData((prevData) => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: parsedValue
            }
        }));

        // Обработка зависимостей для адреса
        if (name === 'country_id') {
            fetchStates(parsedValue);
            setCities([]);
            setZipCodes([]);
        }

        if (name === 'state_id') {
            fetchCities(parsedValue);
            setZipCodes([]);
        }

        if (name === 'city_id') {
            fetchZipCodes(parsedValue);
        }
    };

    // Загрузка начальных данных
    useEffect(() => {
        if (initialData) {
            if (isAttorney) {
                setFormData((prevData) => ({
                    ...prevData,
                    first_name: initialData.first_name || '',
                    last_name: initialData.last_name || '',
                    email: initialData.email || '',
                    phone_number: initialData.phone_number || '',
                    description: initialData.description || '',
                    license_year: initialData.license_year || '',
                    total_won_amount: initialData.total_won_amount || '',
                    web_site: initialData.web_site || '',
                    social_media: initialData.social_media?.map(social => ({
                        social_media_id: social.social_media_id || '',
                        platform: social.platform || {},
                        url: social.url || ''
                    })) || [],
                    additional_phones: initialData.additional_phones || [],
                    address: {
                        country_id: initialData?.addresses?.[0]?.country?.country_id || 1,
                        state_id: Number(initialData?.addresses?.[0]?.state?.state_id) || '',
                        city_id: Number(initialData?.addresses?.[0]?.city?.city_id) || '',
                        zip_code_id: Number(initialData?.addresses?.[0]?.zip_code?.id) || '',
                        street_address: initialData?.addresses?.[0]?.street_address || '',
                    },
                    free_consultation: initialData?.free_consultation || false,
                }));
                if (initialData?.addresses?.[0]?.country?.country_id) {
                    fetchStates(Number(initialData.addresses[0].country.country_id));
                }
                if (initialData?.addresses?.[0]?.state?.state_id) {
                    fetchCities(Number(initialData.addresses[0].state.state_id));
                }
                if (initialData?.addresses?.[0]?.city?.city_id) {
                    fetchZipCodes(Number(initialData.addresses[0].city.city_id));
                }
            } else {
                setFormData((prevData) => ({
                    ...prevData,
                    name: initialData.name || '',
                    email: initialData.email || '',
                    foundation_year: initialData.foundation_year || '',
                    phone_number: initialData.phone_number || '',
                    web_site: initialData.web_site || '',
                    description: initialData.description || '',
                    social_media: initialData.social_media?.map(social => ({
                        social_media_id: social.social_media_id || '',
                        platform: social.platform || {},
                        url: social.url || ''
                    })) || [],
                    additional_phones: initialData.additional_phones || [],
                    address: {
                        country_id: initialData?.address?.country?.country_id || 1,
                        state_id: Number(initialData?.address?.state?.state_id) || '',
                        city_id: Number(initialData?.address?.city?.city_id) || '',
                        zip_code_id: Number(initialData?.address?.zip_code?.id) || '',
                        street_address: initialData?.address?.street_address || '',
                    },
                    free_consultation: initialData?.free_consultation || false,
                    working_hours: initialData.working_hours && Array.isArray(initialData.working_hours)
                        ? initialData.working_hours.map(wh => ({
                            working_hours_id: wh.working_hours_id || wh.working_hours?.working_hours_id,
                            day_of_week: wh.day_of_week || wh.working_hours?.day_of_week,
                            start_time: wh.start_time || wh.working_hours?.start_time,
                            end_time: wh.end_time || wh.working_hours?.end_time,
                        }))
                        : [],
                }));
                if (initialData?.address?.country?.country_id) {
                    fetchStates(Number(initialData.address.country.country_id));
                }
                if (initialData?.address?.state?.state_id) {
                    fetchCities(Number(initialData.address.state.state_id));
                }
                if (initialData?.address?.city?.city_id) {
                    fetchZipCodes(Number(initialData.address.city.city_id));
                }
            }
        }
    }, [initialData, isAttorney]);

    // Загрузка стран
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await api.get('/countries/');
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    // Загрузка платформ
    useEffect(() => {
        const fetchPlatforms = async () => {
            try {
                const response = await api.get('/platforms/');
                setPlatforms(response.data);
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };

        fetchPlatforms();
    }, []);

    // Загрузка методов контактов
    useEffect(() => {
        const fetchContactMethods = async () => {
            try {
                const response = await api.get('/contact-methods/');
                setContactMethods(response.data);
            } catch (error) {
                console.error('Error fetching contact methods:', error);
            }
        };

        fetchContactMethods();
    }, []);

    // Загрузка штатов, городов, почтовых индексов
    const fetchStates = async (country_id) => {
        try {
            const response = await api.get(`/states-filtered/?country_id=${country_id}`);
            setStates(response.data);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const fetchCities = async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`);
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const fetchZipCodes = async (city_id) => {
        try {
            const response = await api.get(`/zip-codes/?city_id=${city_id}`);
            setZipCodes(response.data);
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        }
    };

    // Обработка изменений в форме
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Обработка изменений социальных сетей
    const handleSocialChange = (index, e) => {
        const {name, value} = e.target;
        const updatedSocialMedia = formData.social_media.map((social, i) => {
            if (i === index) {
                return {...social, [name]: value};
            }
            return social;
        });

        setFormData((prevData) => ({
            ...prevData,
            social_media: updatedSocialMedia
        }));
    };

    // Добавление новой социальной сети
    const handleAddSocialMedia = () => {
        const selectedPlatform = platforms.find(platform => platform.platform_id === Number(newSocialMedia.platform_id));

        if (selectedPlatform && newSocialMedia.url) {
            const updatedSocialMedia = [
                ...formData.social_media,
                {
                    platform: selectedPlatform,
                    url: newSocialMedia.url,
                    social_media_id: newSocialMedia.social_media_id || null
                }
            ];

            setFormData((prevData) => ({
                ...prevData,
                social_media: updatedSocialMedia
            }));

            setNewSocialMedia({platform_id: '', url: '', social_media_id: null});
            setShowAddSocialPopup(false);
        } else {
            console.log("Platform or URL not selected");
        }
    };

    // Удаление социальной сети
    const handleDeleteSocialMedia = (index) => {
        const updatedSocialMedia = formData.social_media.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            social_media: updatedSocialMedia
        }));
    };

    // Обработка изменений дополнительных телефонов
    const handlePhoneChange = (index, e) => {
        const {name, value} = e.target;
        const updatedPhones = formData.additional_phones.map((phone, i) => {
            if (i === index) {
                return {...phone, [name]: value};
            }
            return phone;
        });

        setFormData((prevData) => ({
            ...prevData,
            additional_phones: updatedPhones
        }));
    };

    // Добавление нового телефона
    const handleAddPhone = () => {
        if (newPhone.phone_type_id && newPhone.phone_number) {
            const selectedPhoneType = contactMethods.find(method => method.value[1] === Number(newPhone.phone_type_id));

            const updatedPhones = [
                ...formData.additional_phones,
                {
                    phone_type_id: Number(newPhone.phone_type_id),
                    phone_type: selectedPhoneType.name,
                    phone_number: newPhone.phone_number
                }
            ];

            setFormData((prevData) => ({
                ...prevData,
                additional_phones: updatedPhones
            }));

            setNewPhone({phone_type_id: '', phone_number: ''});
            setShowAddPhonePopup(false);
        }
    };

    // Удаление телефона
    const handleDeletePhone = (index) => {
        const updatedPhones = formData.additional_phones.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            additional_phones: updatedPhones
        }));
    };

    // Обработка сабмита формы
    const handleSubmit = (e) => {
        e.preventDefault();
        const submissionData = {
            ...formData,
            working_hours: formData.working_hours.map((wh) => ({
                working_hours_id: wh.working_hours_id || null,
                day_of_week: wh.day_of_week,
                start_time: wh.start_time,
                end_time: wh.end_time,
            })),
        };
        console.log('Form data before submission:', submissionData);
        onSave(submissionData);
    };

    // Отключение отправки формы при нажатии Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    if (!show) return null;

    return (
        <div className="modal visible" id="edit-profile">
            <div className="modal__overlay" onClick={closeEditProfilePopup}></div>
            <div className="modal__content">
                <button onClick={closeEditProfilePopup} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        <h2>Edit {isAttorney ? "Attorney" : "Company"} Profile</h2>

                        {isAttorney ? (
                            <>
                                <InputWithLabel
                                    label='First Name'
                                    name='first_name'
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                                <InputWithLabel
                                    label='Last Name'
                                    name='last_name'
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                                <InputWithLabel
                                    label='License Year'
                                    name='license_year'
                                    type='number'
                                    value={formData.license_year}
                                    onChange={handleChange}
                                    min="1900"
                                    max={currentYear}
                                />
                                <InputWithLabel
                                    label='Total Won Amount'
                                    name='total_won_amount'
                                    type='number'
                                    value={formData.total_won_amount}
                                    onChange={handleChange}
                                />
                            </>
                        ) : (
                            <>
                                <InputWithLabel
                                    label='Name'
                                    name='name'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <InputWithLabel
                                    label='Foundation Year'
                                    name='foundation_year'
                                    type='number'
                                    value={formData.foundation_year}
                                    onChange={handleChange}
                                    min="1800"
                                    max={currentYear}
                                />
                            </>
                        )}

                        <InputWithLabel
                            label='Email'
                            name='email'
                            type='email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                        <InputWithLabel
                            label='Phone Number'
                            name='phone_number'
                            type='tel'
                            value={formData.phone_number}
                            onChange={handleChange}
                        />
                        <InputWithLabel
                            label='Website'
                            name='web_site'
                            type='url'
                            value={formData.web_site}
                            onChange={handleChange}
                        />
                        <TextareaWithLabel
                            label='Description'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                        />

                        {/* Отображаем поле "Free Consultation" только если service_type отсутствует */}
                        {!initialData?.service_type && (
                            <div className='free-consultation-toggle'>
                                <span>Free Consultation</span>
                                <label
                                    onClick={() => setFormData((prevData) => ({
                                        ...prevData,
                                        free_consultation: !prevData.free_consultation,
                                    }))}
                                    className={formData.free_consultation ? 'toggle checked' : 'toggle'}
                                >
                                    <div className='toggle__container'>
                                        <div className='toggle__circle'></div>
                                    </div>
                                </label>
                            </div>
                        )}

                        <span>Address</span>
                        {/* Поля для адреса */}
                        <select
                            className="input"
                            value={Number(formData.address.country_id)}
                            onChange={(e) => handleAddressChange('country_id', e.target.value)}
                        >
                            <option value="" disabled>Choose country</option>
                            {countries.map((country) => (
                                <option key={country.country_id} value={Number(country.country_id)}>
                                    {country.name}
                                </option>
                            ))}
                        </select>

                        {states.length > 0 && (
                            <select
                                className="input"
                                value={Number(formData.address.state_id)}
                                onChange={(e) => handleAddressChange('state_id', e.target.value)}
                            >
                                <option value="" disabled>Choose state</option>
                                {states.map((state) => (
                                    <option key={Number(state.state_id)} value={Number(state.state_id)}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        {cities.length > 0 && (
                            <select
                                className="input"
                                value={Number(formData.address.city_id)}
                                onChange={(e) => handleAddressChange('city_id', e.target.value)}
                            >
                                <option value="" disabled>Choose city</option>
                                {cities.map((city) => (
                                    <option key={Number(city.city_id)} value={Number(city.city_id)}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        {zipCodes.length > 0 && (
                            <select
                                className="input"
                                value={Number(formData.address.zip_code_id) || ''}
                                onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                            >
                                <option value="" disabled>Choose zip code</option>
                                {zipCodes.map((zip) => (
                                    <option key={zip.id} value={zip.id}>
                                        {zip.zip_code}
                                    </option>
                                ))}
                            </select>
                        )}

                        <InputWithLabel
                            label='Street Address'
                            name='street_address'
                            value={formData.address.street_address}
                            onChange={(e) => handleAddressChange('street_address', e.target.value)}
                        />

                        {/* Социальные сети */}
                        <span>Social Networks</span>
                        {formData.social_media.map((social, index) => (
                            <InputWithLabel
                                key={index}
                                label={social.platform.name}
                                name="url"
                                value={social.url}
                                onChange={(e) => handleSocialChange(index, e)}
                                showDelete={true}
                                onDelete={() => handleDeleteSocialMedia(index)}
                            />
                        ))}
                        <button
                            className="underline no-style"
                            type="button"
                            onClick={() => setShowAddSocialPopup(true)}
                        >
                            Add Other Network
                        </button>

                        {showAddSocialPopup && (
                            <div className="modal visible" id="add-social">
                                <div className="modal__overlay" onClick={() => setShowAddSocialPopup(false)}></div>
                                <div className="modal__content">
                                    <button
                                        className="button no-style close js--close-modal"
                                        onClick={() => setShowAddSocialPopup(false)}
                                    >
                                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                                    </button>
                                    <div className="modal__form">
                                        <form className="content" onSubmit={(e) => {
                                            e.preventDefault();
                                            handleAddSocialMedia();
                                        }}>
                                            <h2>Add Social Network</h2>
                                            <select
                                                className="input"
                                                value={newSocialMedia.platform_id}
                                                onChange={(e) => setNewSocialMedia({
                                                    ...newSocialMedia,
                                                    platform_id: e.target.value
                                                })}
                                            >
                                                <option value="" disabled>Choose platform</option>
                                                {platforms.map((platform) => (
                                                    <option key={platform.platform_id} value={platform.platform_id}>
                                                        {platform.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <InputWithLabel
                                                label='Add link'
                                                name='url'
                                                value={newSocialMedia.url}
                                                onChange={(e) => setNewSocialMedia({
                                                    ...newSocialMedia,
                                                    url: e.target.value
                                                })}
                                            />

                                            <button className="button button-black" type="button"
                                                    onClick={handleAddSocialMedia}>
                                                Add Profile
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Дополнительные телефоны */}
                        <span>Additional Phones</span>
                        {formData.additional_phones.map((phone, index) => (
                            <InputWithLabel
                                key={index}
                                label={phone.phone_type.name}
                                name="phone_number"
                                value={phone.phone_number}
                                onChange={(e) => handlePhoneChange(index, e)}
                                showDelete={true}
                                onDelete={() => handleDeletePhone(index)}
                            />
                        ))}
                        <button
                            className="underline no-style"
                            type="button"
                            onClick={() => setShowAddPhonePopup(true)}
                        >
                            Add Another Phone
                        </button>

                        {showAddPhonePopup && (
                            <div className="modal visible" id="add-phone">
                                <div className="modal__overlay" onClick={() => setShowAddPhonePopup(false)}></div>
                                <div className="modal__content">
                                    <button
                                        className="button no-style close js--close-modal"
                                        onClick={() => setShowAddPhonePopup(false)}
                                    >
                                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                                    </button>
                                    <div className="modal__form">
                                        <form className="content" onSubmit={(e) => {
                                            e.preventDefault();
                                            handleAddPhone();
                                        }}>
                                            <h2>Add Phone</h2>
                                            <select
                                                className="input"
                                                value={newPhone.phone_type_id}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    console.log('Selected phone_type_id:', newValue);
                                                    setNewPhone({
                                                        ...newPhone,
                                                        phone_type_id: newValue
                                                    });
                                                }}
                                            >
                                                <option value="" disabled>Choose phone type</option>
                                                {contactMethods
                                                    .filter(method => Array.isArray(method.value))
                                                    .map((method) => (
                                                        <option key={method.value[1]} value={method.value[1]}>
                                                            {method.name}
                                                        </option>
                                                    ))}
                                            </select>

                                            <InputWithLabel
                                                label='Phone Number'
                                                name='phone_number'
                                                value={newPhone.phone_number}
                                                onChange={(e) => {
                                                    const newValue = e.target.value;
                                                    console.log('Entered phone number:', newValue);
                                                    setNewPhone({
                                                        ...newPhone,
                                                        phone_number: newValue
                                                    });
                                                }}
                                            />

                                            <button className="button button-black" type="button" onClick={() => {
                                                console.log('Phone type before adding:', newPhone.phone_type_id);
                                                console.log('Phone number before adding:', newPhone.phone_number);
                                                handleAddPhone();
                                            }}>
                                                Add Phone
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Работа с рабочими часами */}
                        {!isAttorney && (
                            <>
                                <WorkingHoursDisplay workingHours={formData.working_hours}/>
                                <button
                                    type="button"
                                    className="button button-border"
                                    onClick={() => setShowEditWorkingHoursPopup(true)}
                                >
                                    Edit Working Hours
                                </button>
                            </>
                        )}

                        {/* Добавление модального окна для редактирования рабочих часов */}
                        {showEditWorkingHoursPopup && (
                            <WorkingHoursEditor
                                workingHours={formData.working_hours}
                                setWorkingHours={(updatedWorkingHours) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        working_hours: updatedWorkingHours,
                                    }));
                                }}
                                onClose={() => setShowEditWorkingHoursPopup(false)}
                            />
                        )}

                        <button className="button button-black" type="submit">Save Profile</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProfilePopup;
