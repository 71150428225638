import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Popup.css';
import ZipCodes from "../Header/ZipCodes";
import api from '../../api';
import { useUser } from '../../context/UserContext';  // Импортируем хук useUser
import Cookies from 'js-cookie';
import EditProfilePopup from "../popups/EditProfilePopup";
import EditLanguagesPopup from "../popups/EditLanguagesPopup";
import EditServicesPopup from "../popups/EditServicesPopup";
import EditAwardsPopup from "../popups/EditAwardsPopup";
import EditWorkersPopup from "../popups/EditWorkersPopup";

const PopupVendor = ({ visible, vendor, onClose, showSharePopup }) => {
    const { user } = useUser();  // Получаем текущего пользователя
    const [expandedCities, setExpandedCities] = useState({});
    const [showAllWorkers, setShowAllWorkers] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [currentVendor, setVendor] = useState(vendor);
    const [currentLogo, setLogo] = useState(vendor.logo);
    const [currentBackgroundImage, setBackgroundImage] = useState(vendor.background_image);
    const [showEditProfilePopup, setShowEditProfilePopup] = useState(false);
    const [showEditLanguagesPopup, setShowEditLanguagesPopup] = useState(false);
    const [showEditServicesPopup, setShowEditServicesPopup] = useState(false);
    const [showEditAwardsPopup, setShowEditAwardsPopup] = useState(false);
    const [showEditWorkersPopup, setShowEditWorkersPopup] = useState(false);

    // Обновляем данные при изменении пропса vendor
    useEffect(() => {
        console.log('Initial vendor data:', vendor);
        setVendor(vendor);
        setLogo(vendor.logo);
        setBackgroundImage(vendor.background_image);
    }, [vendor]);

    // Функция для получения данных вендора с сервера
    const fetchVendorData = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            };
            const response = await api.get(`/vendors/${currentVendor.vendor_id}/`, config);
            setVendor(response.data);
            setLogo(response.data.logo);  // Обновляем логотип
            setBackgroundImage(response.data.background_image);  // Обновляем фоновое изображение
        } catch (error) {
            console.error('Error fetching vendor data:', error);
        }
    };

    // Обработчики для открытия и закрытия всплывающих окон редактирования
    const handlerShowEditProfilePopup = useCallback(() => {
        console.log('Show edit')
        setShowEditProfilePopup(true);
    }, []);

    const handlerCloseEditProfilePopup = useCallback(() => {
        setShowEditProfilePopup(false);
    }, []);

    // Функция для получения конфигурации запроса с токенами
    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    };

    // Функция для обновления данных вендора
    const updateVendorData = async (vendorId, data, config) => {
        try {
            const response = await api.patch(`/vendor/${vendorId}/update/`, data, config);
            return response;
        } catch (error) {
            console.error('Error updating vendor:', error);
            throw error;
        }
    };

    // Функция для сохранения профиля
    const handleSaveProfile = async (updatedData) => {
        try {
            const config = getRequestConfig();
            const response = await updateVendorData(currentVendor.vendor_id, updatedData, config);

            if (response.status === 200) {
                await fetchVendorData();
                handlerCloseEditProfilePopup();
            }
        } catch (error) {
            // Обработка ошибок, если необходимо
        }
    };

    // Функция для обновления полей
    const handleFieldUpdate = async (fieldName, value) => {
        const formData = new FormData();
        formData.append(fieldName, value);

        try {
            const config = getRequestConfig();

            if (fieldName === 'logo' || fieldName === 'background_image') {
                const tempURL = URL.createObjectURL(value);
                if (fieldName === 'logo') {
                    setLogo(tempURL);
                } else if (fieldName === 'background_image') {
                    setBackgroundImage(tempURL);
                }
            }

            const response = await updateVendorData(currentVendor.vendor_id, formData, config);

            if (response.status === 200) {
                await fetchVendorData();
            }
        } catch (error) {
            // Обработка ошибок, если необходимо
        }
    };

    // Обработчики изменения логотипа и фонового изображения
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFieldUpdate('logo', file);
        }
    };

    const handleBackgroundImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFieldUpdate('background_image', file);
        }
    };

    // Функция для форматирования номера телефона
    const formatPhoneNumber = (phoneNumber) => {
        let cleaned = ('' + phoneNumber).replace(/\D/g, '');
        if (!cleaned.startsWith('1')) {
            cleaned = '1' + cleaned;
        }
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    // Сокращения для штатов
    const stateAbbreviations = {
        "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA", "Colorado": "CO",
        "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA", "Hawaii": "HI", "Idaho": "ID",
        "Illinois": "IL", "Indiana": "IN", "Iowa": "IA", "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA",
        "Maine": "ME", "Maryland": "MD", "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN",
        "Mississippi": "MS", "Missouri": "MO", "Montana": "MT", "Nebraska": "NE", "Nevada": "NV",
        "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM", "New York": "NY", "North Carolina": "NC",
        "North Dakota": "ND", "Ohio": "OH", "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA",
        "Rhode Island": "RI", "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX",
        "Utah": "UT", "Vermont": "VT", "Virginia": "VA", "Washington": "WA", "West Virginia": "WV",
        "Wisconsin": "WI", "Wyoming": "WY"
    };

    const [isFavorite, setIsFavorite] = useState(vendor.is_favorite);

    // Устанавливаем мета-теги и заголовок страницы
    useEffect(() => {
        if (visible && vendor) {
            document.title = vendor.name || "Vendor Profile";

            const updateMetaTag = (property, content) => {
                let metaTag = document.querySelector(`meta[property='${property}']`);
                if (metaTag) {
                    metaTag.setAttribute('content', content);
                } else {
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', property);
                    metaTag.setAttribute('content', content);
                    document.head.appendChild(metaTag);
                }
            };

            updateMetaTag('og:title', vendor.name);
            updateMetaTag('og:description', vendor.description);
            updateMetaTag('og:image', vendor.logo);
            updateMetaTag('og:url', window.location.href);
        }
    }, [visible, vendor]);

    // Деструктуризация данных вендора с установкой значений по умолчанию
    const {
        name = "Vendor Name",
        description = "Vendor description",
        address = {
            street_address: "123 Vendor St",
            city: { name: "Vendor City" },
            state: { name: "Vendor State" },
            country: { name: "Vendor Country" },
            zip_codes: "90001"
        },
        email = "vendor@example.com",
        phone_number = "+1234567890",
        additional_phones = [],
        web_site = "https://vendorwebsite.com",
        social_media = [],
        working_hours = [],
        detailed_service_regions = [],
        logo = null,
        background_image = '/assets/bg--profile-vendor.jpg',
        free_consultation = false,
        awards = [],
        foundation_year = null,
        services_regions = [],
        service_type = { service_type_name: 'Service Type' },
        languages = [],
        workers = [],
        service_subtypes = []

    } = currentVendor || {};

    // Форматированный адрес
    const formattedAddress = `${address.street_address}, ${address.city.name}, ${address.state.name}, ${address.country.name}, ${address.zip_code.zip_code}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
    const appleMapsUrl = `http://maps.apple.com/?q=${encodeURIComponent(formattedAddress)}`;

    // Функция для форматирования времени в AM/PM формат
    const formatTimeToAMPM = (time) => {
        const [hour, minute] = time.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${adjustedHour}:${minute < 10 ? '0' + minute : minute} ${period}`;
    };

    // Проверяем, является ли текущий пользователь владельцем вендора
    const isCurrentUserVendorOwner = () => {
        return user && user.id === vendor.user;
    };

    // Функция для группировки рабочих часов
    const groupWorkingHours = (working_hours) => {
        const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const hoursMap = daysOfWeek.reduce((map, day) => {
            map[day] = 'Closed';
            return map;
        }, {});

        working_hours.forEach(({ day_of_week, start_time, end_time }) => {
            if (day_of_week && start_time && end_time) {
                hoursMap[day_of_week] = `${formatTimeToAMPM(start_time)} - ${formatTimeToAMPM(end_time)}`;
            }
        });

        const groupedHours = [];
        let currentRange = null;
        let currentDays = [];

        const finalizeGroup = () => {
            if (currentDays.length > 0) {
                if (currentDays.length === 1) {
                    groupedHours.push(`${currentDays[0]}: ${currentRange}`);
                } else {
                    groupedHours.push(`${currentDays[0]}-${currentDays[currentDays.length - 1]}: ${currentRange}`);
                }
            }
        };

        daysOfWeek.forEach(day => {
            const hours = hoursMap[day];
            if (currentRange === hours) {
                currentDays.push(day);
            } else {
                finalizeGroup();
                currentDays = [day];
                currentRange = hours;
            }
        });

        finalizeGroup();

        return groupedHours;
    };

    // Проверяем статус избранного
    useEffect(() => {
        const checkFavoriteStatus = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                };
                const response = await api.get(`/check-favorite-vendor/${vendor.vendor_id}/`, config);
                setIsFavorite(response.data.is_favorite);
            } catch (error) {
                console.error('Error checking favorite status:', error);
            }
        };

        checkFavoriteStatus();
    }, [vendor.vendor_id]);

    // Обработчики добавления и удаления из избранного
    const handleAddToFavorites = async () => {
        try {
            const response = await api.post('/add-favorite-vendor/', { vendor_id: vendor.vendor_id });

            if (response.status === 200 || response.status === 201) {
                setIsFavorite(true);
            }
        } catch (error) {
            console.error('Error adding to favorites:', error);
        }
    };

    const handleRemoveFromFavorites = async () => {
        try {
            const response = await api.post('/remove-favorite-vendor/', { vendor_id: vendor.vendor_id });

            if (response.status === 200) {
                setIsFavorite(false);
            }
        } catch (error) {
            console.error('Error removing from favorites:', error);
        }
    };

    const handleToggleFavorites = () => {
        if (isFavorite) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };

    // Получаем отформатированные рабочие часы
    const workingHoursFormatted = groupWorkingHours(working_hours);

    // Группировка регионов обслуживания
    const groupedServiceRegions = detailed_service_regions.reduce((acc, region) => {
        const cityState = `${region.city.name}, ${stateAbbreviations[region.state.name]}`;
        if (!acc[cityState]) {
            acc[cityState] = [];
        }
        acc[cityState].push(region.zip_code.zip_code);
        return acc;
    }, {});

    const groupedServicesRegions = services_regions.reduce((acc, region) => {
        const cityState = `${region.city.name}, ${stateAbbreviations[region.state.name]}`;
        if (!acc[cityState]) {
            acc[cityState] = [];
        }
        acc[cityState].push(region.postal_code);
        return acc;
    }, {});

    // Функция для переключения отображения городов
    const toggleCityExpansion = (city) => {
        setExpandedCities(prevState => ({
            ...prevState,
            [city]: !prevState[city]
        }));
    };

    // Отображаемых работников (ограничиваем до 2, если не выбрано "показать всех")
    const displayedWorkers = showAllWorkers ? workers : workers.slice(0, 2);
    const remainingWorkersCount = workers.length - 2;

    // Функция для отображения типа услуги
    const renderServiceType = () => {
        if (service_type && service_type.service_type_name) {
            return (
                <span className="vendor__status vendor__status-active">{service_type.service_type_name}</span>
            );
        }
        return null;
    };

    // Обработчики открытия и закрытия всплывающих окон редактирования языков
    const handlerShowEditLanguagesPopup = useCallback(() => {
        setShowEditLanguagesPopup(true);
    }, []);

    const handlerCloseEditLanguagesPopup = useCallback(() => {
        setShowEditLanguagesPopup(false);
    }, []);

    // Функция для сохранения языков
    const handleSaveLanguages = async (updatedData) => {
        try {
            const languagesToSave = updatedData.map(language => ({
                language_id: language.language_id, // Отправляем только id
                proficiency_level: language.proficiency_level // Добавляем уровень владения языком
            }));

            const config = getRequestConfig();
            const response = await updateVendorLanguages(currentVendor.vendor_id, languagesToSave, config);

            if (response.status === 200) {
                await fetchVendorData();
                handlerCloseEditLanguagesPopup();
            }
        } catch (error) {
            console.error('Error saving languages:', error);
        }
    };

    // Функция для обновления языков вендора
    const updateVendorLanguages = async (vendorId, languages, config) => {
        try {
            const response = await api.patch(`/vendor/${vendorId}/languages/`, { languages }, config);  // Указываем правильный URL
            return response;
        } catch (error) {
            console.error('Error updating languages:', error);
            throw error;
        }
    };

    // Обработчики для всплывающего окна редактирования услуг
    const handlerShowEditServicesPopup = useCallback(() => {
        setShowEditServicesPopup(true);
    }, []);

    const handlerCloseEditServicesPopup = useCallback(() => {
        setShowEditServicesPopup(false);
    }, []);

    // Функция для сохранения услуг
    const handleSaveServices = async (updatedServices) => {
        try {
            const config = getRequestConfig();  // Получаем конфигурацию для запроса
            const response = await api.patch(`/vendor/${currentVendor.vendor_id}/services/`, { services: updatedServices }, config);

            if (response.status === 200) {
                await fetchVendorData();
                handlerCloseEditServicesPopup();
            }
        } catch (error) {
            console.error('Error saving services:', error);
        }
    };

    // Обработчики для всплывающего окна редактирования наград
    const handlerShowEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(true);
    }, []);

    const handlerCloseEditAwardsPopup = useCallback(() => {
        setShowEditAwardsPopup(false);
    }, []);

    const handleSaveAwards = async (formDataToSend) => {
        try {
            const config = getRequestConfig();
            const response = await api.patch(`/vendor/${currentVendor.vendor_id}/awards/`, formDataToSend, config);

            if (response.status === 200) {
                await fetchVendorData();
                handlerCloseEditAwardsPopup();
            }
        } catch (error) {
            console.error('Error saving awards:', error);
        }
    };

    // Обработчики для всплывающего окна редактирования работников
    const handleSaveWorkers = async (formData) => {
        try {
            const config = getRequestConfig();
            const response = await api.patch(`/vendor/${currentVendor.vendor_id}/workers/`, formData, config);

            if (response.status === 200) {
                await fetchVendorData();
                handlerCloseEditWorkersPopup();
            }
        } catch (error) {
            console.error('Error saving workers:', error);
        }
    };

    const handlerCloseEditWorkersPopup = useCallback(() => {
        setShowEditWorkersPopup(false);
    }, []);

    const handlerShowEditWorkersPopup = useCallback(() => {
        setShowEditWorkersPopup(true);
    }, []);

    return (
        <div className={`popup ${visible ? 'visible' : ''}`} id="company-type1">
            <button className="close js--hide-popup" onClick={onClose}>
                <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
            </button>
            <div className="profile profile-pro">
                <div className="profile__headline profile__headline-company"
                    style={{ background: `url(${currentBackgroundImage})` }}>
                    <div className="container">
                        <div className="profile__logo">
                            {currentLogo && (
                                <picture>
                                    <source type="image/webp" srcSet={currentLogo} />
                                    <source type="image/jpg" srcSet={currentLogo} />
                                    <img src={currentLogo} alt={`${name} logo`} />
                                </picture>
                            )}
                            {isCurrentUserVendorOwner() && (
                                <label className="userpick__edit"><img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit Logo" />
                                    <input type="file" onChange={handleLogoChange} />
                                </label>
                            )}
                        </div>
                        {isCurrentUserVendorOwner() && (
                            <label className="bg__edit">
                                <div className="button no-style"><img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Background" /> Edit Big Photo
                                </div>
                                <input type="file" onChange={handleBackgroundImageChange} />
                            </label>
                        )}
                    </div>
                </div>
                <div className="profile__block">
                    <div className="container">
                        <div className="profile__header">
                            <div className="vendor__header">
                                <h1 className="vendor__name">{name}</h1>
                                <button
                                    className={`favorites ${isFavorite ? 'added' : ''}`}
                                    onClick={handleToggleFavorites}
                                >
                                    <svg>
                                        <path fillRule="evenodd" clipRule="evenodd"
                                            d="M1.58733 3.10072C3.70393 0.940385 7.13582 0.940385 9.25246 3.10072L10.0323 3.89665L10.7475 3.16683C12.8641 1.0065 16.296 1.0065 18.4127 3.16683C20.5293 5.32717 20.5292 8.82998 18.4127 10.9904L10.4695 19.0901C10.4418 19.1184 10.5003 19.068 10.4695 19.0901C10.2068 19.2849 9.79324 19.2991 9.5566 19.0576L1.58757 10.9239C-0.52903 8.76353 -0.529268 5.26111 1.58733 3.10072Z"></path>
                                    </svg>
                                </button>
                                <div className="verified">
                                    <img src="/assets/icon--verified-CxOX3a18.svg" alt="Verified" />
                                </div>
                                <div className="vendor__attorney">
                                    {foundation_year && (
                                        <span> Founded In {foundation_year} </span>
                                    )}
                                    {renderServiceType()}
                                </div>
                            </div>
                            {isCurrentUserVendorOwner() && (
                                <button className="button no-style" onClick={handlerShowEditProfilePopup}>
                                    <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit" /> Edit profile
                                </button>
                            )}

                        </div>
                        <div className="profile__content userdata">
                            <div className="col-2">
                                <div className="data">
                                    <p>{description}</p>
                                </div>
                                <div className="profile__contacts">
                                    <ul className="contacts">
                                        {address && address.street_address && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Address</div>
                                                <div className="contacts__value">
                                                    <a href={googleMapsUrl} target="_blank"
                                                        rel="noopener noreferrer">{formattedAddress}</a>
                                                </div>
                                            </li>
                                        )}
                                        {working_hours.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Working Hours</div>
                                                <div className="contacts__value">
                                                    <ul className="hours">
                                                        {workingHoursFormatted.length > 0 ? workingHoursFormatted.map((item, index) => {
                                                            const [day, time] = item.split(': ');
                                                            return (
                                                                <li key={index}>
                                                                    <span className="working-hours__day">{day}:</span>
                                                                    <span className="working-hours__time">{time}</span>
                                                                </li>
                                                            );
                                                        }) : <li>Closed</li>}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                        {email && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Email</div>
                                                <div className="contacts__value">
                                                    <a href={`mailto:${email}`}>{email}</a>
                                                </div>
                                            </li>
                                        )}
                                        {phone_number && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Phone</div>
                                                <div className="contacts__value">
                                                    <a href={`tel:${phone_number}`}>{formatPhoneNumber(phone_number)}</a>
                                                </div>
                                            </li>
                                        )}
                                        {additional_phones.length > 0 && (
                                            additional_phones.map((phone, index) => (
                                                <li key={index} className="contacts__el">
                                                    <div className="contacts__label">{phone.phone_type.name}</div>
                                                    <div className="contacts__value">
                                                        <a href={`tel:${phone.phone_number}`}>{formatPhoneNumber(phone.phone_number)}</a>
                                                    </div>
                                                </li>
                                            ))
                                        )}
                                        {web_site && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Web Site</div>
                                                <div className="contacts__value">
                                                    <a href={web_site} target="_blank"
                                                        rel="noopener noreferrer">{web_site}</a>
                                                </div>
                                            </li>
                                        )}
                                        {social_media.length > 0 && (
                                            <li className="contacts__el">
                                                <div className="contacts__label">Social Media</div>
                                                <div className="contacts__value">
                                                    <ul className="social">
                                                        {social_media.map((sm, index) => (
                                                            <li key={index}>
                                                                <a href={sm.url} target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    {sm.platform.icon ? (
                                                                        <img
                                                                            src={sm.platform.icon}
                                                                            alt={sm.platform.name} />
                                                                    ) : (
                                                                        sm.platform.name
                                                                    )}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                    <button onClick={() => showSharePopup({
                                        companyName: name,
                                        telephone: phone_number,
                                        address: formattedAddress,
                                        specializations: vendor.languages.map(lang => lang.name)
                                    })} type='button'
                                        className='profile__share button no-style'>
                                        Share Profile
                                        <img src="/assets/icon--share.svg" alt="Share Icon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*{(isCurrentUserVendorOwner() || detailed_service_regions.length > 0) && (*/}
                {/*    <div className="profile__block">*/}
                {/*        <div className="container">*/}
                {/*            <div className="profile__header">*/}
                {/*                <h2>Service Regions</h2>*/}
                {/*                {isCurrentUserVendorOwner() && (*/}
                {/*                    <button className="button no-style">*/}
                {/*                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Regions" />*/}
                {/*                        {(detailed_service_regions.length === 0) ? (*/}
                {/*                            <>*/}
                {/*                                Add Regions*/}
                {/*                            </>*/}
                {/*                        ) : (*/}
                {/*                            <>*/}
                {/*                                Edit Regions*/}
                {/*                            </>*/}
                {/*                        )*/}
                {/*                        }*/}
                {/*                    </button>*/}
                {/*                )}*/}
                {/*            </div>*/}
                {/*            {detailed_service_regions.length > 0 ? (*/}
                {/*                <div className="profile__content">*/}
                {/*                    <ul className="params__list">*/}
                {/*                        {Object.keys(groupedServiceRegions).map((cityState, index) => (*/}
                {/*                            <li key={index}>*/}
                {/*                                <div>*/}
                {/*                                    <div*/}
                {/*                                        onClick={() => toggleCityExpansion(cityState)}*/}
                {/*                                        style={{ cursor: 'pointer', transition: 'height 0.3s ease' }}*/}
                {/*                                    >*/}
                {/*                                        {cityState} <ZipCodes*/}
                {/*                                            zipCodes={groupedServiceRegions[cityState]} />*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            </li>*/}
                {/*                        ))}*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            ) : (*/}
                {/*                isCurrentUserVendorOwner() && (*/}
                {/*                    <div className="profile__content">*/}
                {/*                    </div>*/}
                {/*                )*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
                {(isCurrentUserVendorOwner() || service_subtypes.length > 0) ? (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Services</h2>
                                {isCurrentUserVendorOwner() && (
                                    <button className="button no-style" onClick={handlerShowEditServicesPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Services" />
                                        {(service_subtypes.length === 0) ? (
                                            <>
                                                Add Services
                                            </>
                                        ) : (
                                            <>
                                                Edit Services
                                            </>
                                        )
                                        }
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <ul className="params__list">
                                    {service_subtypes.map((subtype, index) => (
                                        <li key={index}>{subtype.service_subtype.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    (isCurrentUserVendorOwner() && (
                        <div className="profile__content">
                        </div>
                    )
                    )
                )}
                {(isCurrentUserVendorOwner() || languages.length > 0) ? (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Languages</h2>
                                {isCurrentUserVendorOwner() && (
                                    <button className="button no-style" onClick={handlerShowEditLanguagesPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Languages" />
                                        {(languages.length === 0) ? (
                                            <>
                                                Add Languages
                                            </>
                                        ) : (
                                            <>
                                                Edit Languages
                                            </>
                                        )
                                        }
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <ul className="params__list">
                                    {languages.map((lang, index) => (
                                        <li key={index}>{lang.name} ({lang.proficiency_level})</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    (isCurrentUserVendorOwner() && (
                        <div className="profile__content">
                        </div>
                    )
                    )
                )}
                {(isCurrentUserVendorOwner() || awards.length > 0) ? (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>Awards and Articles</h2>
                                {isCurrentUserVendorOwner() && (
                                    <button className="button no-style" onClick={handlerShowEditAwardsPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Awards" />
                                        {(awards.length === 0) ? (
                                            <>
                                                Add Awards
                                            </>
                                        ) : (
                                            <>
                                                Edit Awards
                                            </>
                                        )
                                        }
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="col-2 decor-list">
                                    {awards.map((award, index) => (
                                        <div key={index} className="vendor vendor-award decor-list__el">
                                            <div className="vendor__award">
                                                {award.image && <img src={award.image} alt={award.title} />}
                                            </div>
                                            <div className="vendor__header">
                                                <h4 className="vendor__name">{award.title}</h4>
                                                <span className="vendor__attorney">{award.subtitle}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    (isCurrentUserVendorOwner() && (
                        <div className="profile__content">
                        </div>
                    )
                    )
                )}
                {(isCurrentUserVendorOwner() || workers.length > 0) ? (
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header"><h2>{workers.length} workers on staff</h2>
                                {isCurrentUserVendorOwner() && (
                                    <button className="button no-style" onClick={handlerShowEditWorkersPopup}>
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Workers" />
                                        {(workers.length === 0) ? (
                                            <>
                                                Add Workers
                                            </>
                                        ) : (
                                            <>
                                                Edit Workers
                                            </>
                                        )
                                        }
                                    </button>
                                )}
                            </div>
                            <div className="profile__content">
                                <div className="attorneys">
                                    <ul className="attorneys__list">
                                        {displayedWorkers.map((worker, index) => (
                                            <li key={index} className="attorney" style={{ cursor: 'default' }}>
                                                <div className="attorney__avatar">
                                                    <div className="avatar">
                                                        <picture>
                                                            {worker.photo && (
                                                                <>
                                                                    <source type="image/webp" srcSet={worker.photo} />
                                                                    <source type="image/jpg" srcSet={worker.photo} />
                                                                    <img src={worker.photo}
                                                                        alt={`${worker.first_name} ${worker.last_name}`} />
                                                                </>
                                                            )}
                                                        </picture>
                                                    </div>
                                                </div>
                                                <div className="attorney__content">
                                                    <div>
                                                        <h3 className="attorney__name">{worker.first_name} {worker.last_name}</h3>
                                                        <div className="attorney__info">
                                                            {worker.positions && worker.positions.length > 0 && (
                                                                <span>{worker.positions.map(position => position.title).join(', ')}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <ul className="params__list">
                                                        {worker.specializations.slice(0, 5).map((spec, specIndex) => (
                                                            <li key={specIndex}>{spec.name}</li>
                                                        ))}
                                                        {worker.specializations.length > 5 && (
                                                            <li>+ {worker.specializations.length - 5} more</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    {workers.length > 2 && (
                                        <div className="show-all">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setShowAllWorkers(!showAllWorkers);
                                            }}>
                                                {showAllWorkers ? 'Show Less' : `Show More Workers • ${remainingWorkersCount} (${workers.length})`}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    (isCurrentUserVendorOwner() && (
                        <div className="profile__content">
                        </div>
                    )
                    )
                )}
            </div>
            {showEditProfilePopup && (
                <EditProfilePopup
                    show={showEditProfilePopup}
                    closeEditProfilePopup={handlerCloseEditProfilePopup}
                    initialData={currentVendor}
                    onSave={handleSaveProfile}
                />
            )}
            {showEditLanguagesPopup && (
                <EditLanguagesPopup
                    show={showEditLanguagesPopup}
                    closeEditLanguagesPopup={handlerCloseEditLanguagesPopup}
                    initialData={currentVendor}
                    onSave={handleSaveLanguages}
                />
            )}
            {showEditServicesPopup && (
                <EditServicesPopup
                    show={showEditServicesPopup}
                    onClose={handlerCloseEditServicesPopup}
                    currentServices={currentVendor.service_subtypes.map(s => s.subtype_id)}  // Передаем текущие услуги
                    onSave={handleSaveServices}
                    vendorId={currentVendor.vendor_id}  // Передаем ID вендора, если нужно
                />
            )}
            {showEditAwardsPopup && (
                <EditAwardsPopup
                    show={showEditAwardsPopup}
                    onClose={handlerCloseEditAwardsPopup}
                    initialData={currentVendor}
                    onSave={handleSaveAwards}
                    vendorId={currentVendor.vendor_id}
                />
            )}
            {showEditWorkersPopup && (
                <EditWorkersPopup
                    show={showEditWorkersPopup}
                    onClose={handlerCloseEditWorkersPopup}
                    initialData={currentVendor}
                    onSave={handleSaveWorkers}
                    vendorId={currentVendor.vendor_id}
                />
            )}

        </div>
    );
};

export default PopupVendor;
