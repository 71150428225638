// EditServiceRegionsPopup.js
import React, { useState, useEffect, useRef } from 'react';
import ZipCodes from '../Header/ZipCodes';
import AddAddressPopup from './AddAddressPopup';
import api from '../../api';
import PropTypes from 'prop-types';
import { useNotification } from '../../context/NotificationContext'; // Импортируем хук для уведомлений

// Объект для сопоставления названий штатов с их аббревиатурами
const stateAbbreviations = {
    "Alabama": "AL", "Alaska": "AK", "Arizona": "AZ", "Arkansas": "AR", "California": "CA", "Colorado": "CO",
    "Connecticut": "CT", "Delaware": "DE", "Florida": "FL", "Georgia": "GA", "Hawaii": "HI", "Idaho": "ID",
    "Illinois": "IL", "Indiana": "IN", "Iowa": "IA", "Kansas": "KS", "Kentucky": "KY", "Louisiana": "LA",
    "Maine": "ME", "Maryland": "MD", "Massachusetts": "MA", "Michigan": "MI", "Minnesota": "MN",
    "Mississippi": "MS", "Missouri": "MO", "Montana": "MT", "Nebraska": "NE", "Nevada": "NV",
    "New Hampshire": "NH", "New Jersey": "NJ", "New Mexico": "NM", "New York": "NY", "North Carolina": "NC",
    "North Dakota": "ND", "Ohio": "OH", "Oklahoma": "OK", "Oregon": "OR", "Pennsylvania": "PA",
    "Rhode Island": "RI", "South Carolina": "SC", "South Dakota": "SD", "Tennessee": "TN", "Texas": "TX",
    "Utah": "UT", "Vermont": "VT", "Virginia": "VA", "Washington": "WA", "West Virginia": "WV",
    "Wisconsin": "WI", "Wyoming": "WY"
};

const EditServiceRegionsPopup = ({ show, onClose, legalCompanyId }) => {
    const [regions, setRegions] = useState({});
    const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
    const [maxServiceRegions, setMaxServiceRegions] = useState(null); // Изначально null
    const [currentRegionsCount, setCurrentRegionsCount] = useState(null); // Изначально null
    const [loading, setLoading] = useState(true); // Состояние загрузки данных
    const [addingSlots, setAddingSlots] = useState(false); // Состояние добавления слотов
    const [error, setError] = useState(null); // Состояние ошибки

    const { showNotification } = useNotification(); // Получаем функцию для отображения уведомлений

    const prevMaxServiceRegions = useRef(null); // Используем useRef для хранения предыдущего значения maxServiceRegions

    // Состояния для тарифов
    const [currentTariff, setCurrentTariff] = useState(null);
    const [nextTariff, setNextTariff] = useState(null);
    const [allTariffs, setAllTariffs] = useState([]); // Состояние для всех тарифов

    // Функция для загрузки всех тарифов с бэкэнда и возврата их
    const fetchAllTariffs = async () => {
        console.log('Fetching all tariffs...');
        try {
            const response = await api.get('/service-regions-tariffs/');
            const tariffs = response.data;
            setAllTariffs(tariffs);
            console.log('Fetched Tariffs:', tariffs);
            return tariffs; // Возвращаем тарифы
        } catch (error) {
            console.error('Error fetching tariffs:', error);
            setError('Failed to load tariffs.');
            showNotification('Failed to load tariffs.', null, null, 'error');
            return []; // Возвращаем пустой массив в случае ошибки
        }
    };

    // Функция для загрузки сервисных регионов с бэкэнда
    const fetchServiceRegions = async () => {
        try {
            const response = await api.get(`/legal-companies/${legalCompanyId}/service-regions/`);
            const serviceRegions = response.data; // Предполагается, что сервисные регионы находятся в корне ответа

            console.log('serviceRegions:', serviceRegions); // Логируем полученные данные для отладки

            // Группируем регионы по городу и штату с использованием аббревиатур
            const groupedRegions = serviceRegions.reduce((acc, region) => {
                const stateName = region.state.name;
                const stateAbbreviation = stateAbbreviations[stateName] || 'Unknown';
                const cityState = `${region.city.name}, ${stateAbbreviation}`;

                if (!acc[cityState]) {
                    acc[cityState] = [];
                }
                acc[cityState].push(region.zip_code.zip_code);
                return acc;
            }, {});
            setRegions(groupedRegions);
            setCurrentRegionsCount(Object.keys(groupedRegions).length); // Устанавливаем текущее количество регионов
        } catch (error) {
            console.error('Error fetching service regions:', error);
            setError('Failed to load service regions.');
            showNotification('Failed to load service regions.', null, null, 'error'); // Уведомление об ошибке загрузки регионов
        }
    };

    // Функция для загрузки данных LegalCompany, включая max_service_regions и тарифные планы
    const fetchLegalCompany = async (tariffs) => { // Принимаем тарифы как параметр
        console.log('Fetching legal company details...');
        try {
            const response = await api.get(`/legal-companies/${legalCompanyId}/`);
            const legalCompany = response.data;
            setMaxServiceRegions(legalCompany.max_service_regions); // Устанавливаем max_service_regions

            // Устанавливаем текущий тариф
            if (legalCompany.service_regions_tariff) {
                console.log('Legal Company Tariff ID:', legalCompany.service_regions_tariff);
                const foundTariff = tariffs.find(tariff => tariff.id === legalCompany.service_regions_tariff);
                if (foundTariff) {
                    setCurrentTariff(foundTariff);
                    console.log('Current Tariff:', foundTariff);
                } else {
                    console.error('Tariff not found for ID:', legalCompany.service_regions_tariff);
                }
            } else {
                console.error('Service regions tariff is undefined');
            }

            // Находим индекс текущего тарифа
            const activeTariffs = tariffs.filter(tariff => tariff.status === 'active');
            activeTariffs.sort((a, b) => a.service_regions_limit - b.service_regions_limit);

            console.log('Active Tariffs IDs:', activeTariffs.map(tariff => tariff.id));
            const currentIndex = activeTariffs.findIndex(tariff => tariff.id === legalCompany.service_regions_tariff);

            // Следующий тариф
            const nextTariffData = activeTariffs[currentIndex + 1] || null;
            setNextTariff(nextTariffData);
            console.log('Next Tariff:', nextTariffData);

            // Проверяем, изменилось ли значение max_service_regions
            if (prevMaxServiceRegions.current !== null && legalCompany.max_service_regions > prevMaxServiceRegions.current) {
                const difference = legalCompany.max_service_regions - prevMaxServiceRegions.current;
                showNotification(`Successfully purchased ${difference} additional slot(s).`, null, null, 'success');
            }

            // Обновляем предыдущее значение
            prevMaxServiceRegions.current = legalCompany.max_service_regions;
        } catch (error) {
            console.error('Error fetching legal company details:', error);
            setError('Failed to load legal company details.');
            showNotification('Failed to load legal company details.', null, null, 'error'); // Уведомление об ошибке загрузки данных компании
        }
    };

    // Функция для покупки дополнительных слотов
    const handleAddSlots = async () => {
        try {
            setAddingSlots(true); // Начинаем процесс добавления слотов
            setError(null); // Сбрасываем предыдущие ошибки

            // Отображаем уведомление о начале процесса покупки
            showNotification('Redirecting to Stripe Checkout...', null, null, 'info');

            // Отправляем запрос на создание сессии Stripe Checkout для покупки дополнительных слотов
            const response = await api.post(`/legal-companies/${legalCompanyId}/add-slots/`, {
                additional_slots: 5
            });

            if (response.status === 200) {
                if (response.data.checkout_url) {
                    // Перенаправляем пользователя на страницу оплаты Stripe
                    window.location.href = response.data.checkout_url;
                } else if (response.data.message) {
                    // Обработка успешного обновления подписки без необходимости Stripe Checkout
                    showNotification(response.data.message, null, null, 'success');
                    // Обновляем данные о компании и регионах
                    await fetchLegalCompany(allTariffs); // Передаем текущие тарифы
                    await fetchServiceRegions();
                } else {
                    // Обработка некорректного ответа
                    setError('Failed to initiate checkout.');
                    showNotification('Failed to initiate checkout.', null, null, 'error'); // Уведомление об ошибке инициирования оплаты
                }
            } else {
                // Обработка некорректного статуса ответа
                setError('Failed to initiate checkout.');
                showNotification('Failed to initiate checkout.', null, null, 'error');
            }
        } catch (error) {
            console.error('Error adding additional slots:', error);
            setError('Error adding additional slots.');
            showNotification('Error adding additional slots.', null, null, 'error'); // Уведомление об ошибке при добавлении слотов
        } finally {
            setAddingSlots(false); // Завершаем процесс добавления слотов
        }
    };

    // Функция для удаления группы сервисных регионов с подтверждением
    const handleDeleteGroup = async (cityState) => {
        try {
            setError(null); // Сбрасываем предыдущие ошибки

            // Получаем данные для данного cityState из regions
            const regionData = regions[cityState];
            console.log(`Attempting to delete group for cityState: "${cityState}"`, regionData);

            if (!regionData) {
                showNotification('Region data not found.', null, null, 'error');
                console.error(`No data found for cityState: "${cityState}"`);
                return;
            }

            // regionData уже является массивом ZIP-кодов
            const zipCodes = regionData;
            console.log(`ZIP-коды для удаления "${cityState}":`, zipCodes);

            if (!zipCodes || zipCodes.length === 0) {
                showNotification('No ZIP codes found for this group.', null, null, 'error');
                console.error(`No ZIP codes to delete for cityState: "${cityState}"`);
                return;
            }

            // Формируем сообщение для подтверждения
            const confirmationMessage = `Are you sure you want to delete the service regions for "${cityState}" with the following ZIP codes: ${zipCodes.join(', ')}?`;

            // Отображаем диалог подтверждения
            const confirmed = window.confirm(confirmationMessage);

            if (!confirmed) {
                // Пользователь отменил действие
                return;
            }

            // Логируем запрос перед отправкой
            console.log(`Confirmed deletion for "${cityState}" with ZIP codes:`, zipCodes);

            // Отправляем DELETE запрос с телом, содержащим список ZIP-кодов
            const response = await api.delete(`/legal-companies/${legalCompanyId}/service-regions/group-delete/`, {
                data: { zip_codes: zipCodes }, // Передаём ZIP-коды в теле запроса
                headers: { 'Content-Type': 'application/json' }
            });

            console.log(`DELETE response for "${cityState}":`, response);

            if (response.status === 200) {
                showNotification('Service regions group deleted successfully.', null, null, 'success');
                await fetchServiceRegions(); // Обновляем список регионов
            } else {
                showNotification('Failed to delete service regions group.', null, null, 'error');
                console.error(`Failed to delete service regions group for "${cityState}":`, response);
            }
        } catch (error) {
            console.error('Error deleting service regions group:', error);
            setError('Failed to delete service regions group.');
            showNotification('Failed to delete service regions group.', null, null, 'error'); // Уведомление об ошибке удаления группы
        }
    };

    // Загружаем сервисные регионы и данные компании при открытии попапа
    useEffect(() => {
        const loadData = async () => {
            setLoading(true); // Начинаем загрузку
            const tariffs = await fetchAllTariffs(); // Загружаем все тарифы и получаем их
            await fetchLegalCompany(tariffs);    // Передаем тарифы в функцию загрузки данных компании
            await fetchServiceRegions();  // Затем получаем сервисные регионы
            setLoading(false); // Завершаем загрузку
        };

        if (show) {
            loadData();
        }
    }, [show, legalCompanyId]);

    // Обработчик после добавления нового региона
    const handleRegionAdded = () => {
        fetchServiceRegions(); // Обновляем список регионов
        setShowAddAddressPopup(false); // Закрываем попап добавления адреса
        showNotification('New service region added successfully.', null, null, 'success'); // Уведомление об успешном добавлении региона
    };

    if (!show) return null;

    // Вычисляем Bonus Slots и Total Slots After Upgrade
    const bonusSlots = nextTariff && currentTariff ? Math.max(nextTariff.service_regions_limit - currentTariff.service_regions_limit, 0) : (nextTariff ? nextTariff.service_regions_limit : 0);
    const totalSlotsAfterUpgrade = currentRegionsCount + bonusSlots;

    // Проверяем, можно ли добавить новый регион, только если данные загружены
    const canAddRegion = !loading && currentRegionsCount < maxServiceRegions;

    return (
        <div className="modal visible" id="edit-services">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <div className="content">
                        <h2>Manage Service Regions</h2>
                        {/* Отображаем сообщение об ошибке, если оно есть */}
                        {error && (
                            <p style={{ color: 'red', marginTop: '10px' }}>
                                {error}
                            </p>
                        )}
                        <ul className="params__list">
                            {Object.keys(regions).map((cityState, index) => (
                                <li key={index}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{ cursor: 'pointer' }}>
                                            {cityState} {/* Отображаем город и аббревиатуру штата */}
                                            {regions[cityState].length > 0 && (
                                                <ZipCodes zipCodes={regions[cityState]} />
                                            )}
                                        </div>
                                        {/* Кнопка для удаления группы сервисных регионов с иконкой корзины */}
                                        <button
                                            type="button"
                                            className="button button-danger"
                                            onClick={() => handleDeleteGroup(cityState)}
                                            title="Delete this service regions group"
                                            aria-label="Delete this service regions group"
                                            style={{
                                                width: '40px', // Задаем фиксированную ширину кнопки
                                                height: '40px', // Задаем фиксированную высоту кнопки
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '0', // Убираем внутренние отступы
                                                borderRadius: '4px' // Добавляем скругление углов, если необходимо
                                            }}
                                        >
                                            <img src="/assets/icon--delete-gray.svg" alt="Delete" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {/* Условное отображение кнопок */}
                        {canAddRegion ? (
                            <button
                                type="button"
                                className="button button-border"
                                onClick={() => setShowAddAddressPopup(true)}
                                disabled={!canAddRegion} // Отключаем кнопку, если нельзя добавить регион
                                title={canAddRegion ? 'Add a new service region' : 'Maximum number of service regions reached'}
                            >
                                {/* Отображаем текущее и максимальное количество регионов */}
                                Add Address ({currentRegionsCount || 0}/{maxServiceRegions || '...'})
                            </button>
                        ) : (
                            <div>
                                <button
                                    type="button"
                                    className={`button button-border ${addingSlots ? 'button-disabled' : ''}`}
                                    onClick={handleAddSlots}
                                    disabled={addingSlots} // Отключаем кнопку во время добавления слотов
                                    title="Purchase additional service region slots"
                                >
                                    {/* Отображаем информацию о добавлении слотов */}
                                    {addingSlots ? 'Adding Slots...' : 'Purchase Additional Service Regions'}
                                </button>
                                {/* Информативное сообщение */}
                                <span className="info-text" style={{ marginTop: '10px', display: 'block' }}>
                                    You have reached the maximum number of service regions.
                                    <ul>
                                        {/* Условное отображение текущего плана */}
                                        {currentTariff ? (
                                            <li>
                                                Current Plan: {currentTariff.service_regions_limit} slots at ${currentTariff.monthly_price} per month
                                            </li>
                                        ) : (
                                            <li>
                                                No current plan. Please connect to a new tariff.
                                            </li>
                                        )}
                                        {/* Отображаем следующий тариф, если он существует */}
                                        {nextTariff && (
                                            <>
                                                <li>
                                                    Next Plan: {nextTariff.service_regions_limit} slots at ${nextTariff.monthly_price} per month
                                                </li>
                                                <li>
                                                    Bonus Slots: {bonusSlots}
                                                </li>
                                                <li>
                                                    Total Slots After Upgrade: {totalSlotsAfterUpgrade}
                                                </li>
                                                <li>
                                                    Monthly Cost: ${nextTariff.monthly_price}
                                                </li>
                                            </>
                                        )}
                                        {/* Если текущий тариф отсутствует и есть следующий тариф */}
                                        {!currentTariff && nextTariff && (
                                            <li>
                                                <button
                                                    type="button"
                                                    className="button button-primary"
                                                    onClick={handleAddSlots}
                                                    disabled={addingSlots}
                                                    style={{ marginTop: '10px' }}
                                                >
                                                    Connect to {nextTariff.service_regions_limit} slots at ${nextTariff.monthly_price} per month
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </span>
                            </div>
                        )}
                        {/* Индикатор загрузки */}
                        {loading && (
                            <span className="loading-info" style={{ marginTop: '10px', display: 'block' }}>
                                Loading...
                            </span>
                        )}
                    </div>
                </div>
            </div>
            {showAddAddressPopup && (
                <AddAddressPopup
                    show={showAddAddressPopup}
                    onClose={() => setShowAddAddressPopup(false)}
                    legalCompanyId={legalCompanyId}
                    onRegionAdded={handleRegionAdded}
                />
            )}
        </div>
    );
};

EditServiceRegionsPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    legalCompanyId: PropTypes.number.isRequired,
};

export default EditServiceRegionsPopup;
