import React, { useCallback, useState } from 'react';
import { getZipCodeFromCoordinates } from './geocode';

const GeolocationButton = ({ onLocationObtained }) => {
    const [userLocation, setUserLocation] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Состояние для загрузки

    const handleNearMeToggle = useCallback(() => {
        setIsChecked(!isChecked);

        if (isChecked) {
            // Очищаем значения при отключении
            setUserLocation('');
            setZipCode('');
            setIsLoading(false); // Останавливаем загрузку
            console.log('Geolocation deactivated');
            onLocationObtained('', '', false); // Возвращаем состояние кнопки
        } else {
            setIsLoading(true); // Начинаем загрузку

            navigator.geolocation.getCurrentPosition(async (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                console.log(`User location: ${latitude}, ${longitude}`);
                setUserLocation({ latitude, longitude });

                try {
                    const zip = await getZipCodeFromCoordinates(latitude, longitude);
                    setZipCode(zip);
                    console.log(`User ZIP code: ${zip}`);
                    onLocationObtained(zip, { latitude, longitude }, true); // Возвращаем состояние кнопки
                } catch (error) {
                    console.error('Error fetching ZIP code:', error);
                    alert('Не удалось получить ZIP-код.');
                    setIsChecked(false);
                }

                setIsLoading(false); // Останавливаем загрузку
            }, (error) => {
                console.error('Error getting geolocation:', error);
                alert('Не удалось получить ваше местоположение.');
                setIsChecked(false);
                setIsLoading(false);
            });
        }
    }, [isChecked, onLocationObtained]);

    return (
        <div className='search__radius'>
            {/* Добавляем класс анимации к тексту Loading */}
            <span className={isLoading ? 'loading-animation' : ''}>
                {isLoading ? 'Loading...' : (userLocation ? `ZIP: ${zipCode}` : 'Near me')}
            </span>
            <label onClick={handleNearMeToggle} className={isChecked ? 'toggle checked' : 'toggle'}>
                <div className='toggle__container'>
                    <div className='toggle__circle'></div>
                </div>
            </label>
        </div>
    );
};

export default GeolocationButton;
