import React, { useState, useEffect, useCallback } from 'react';
import InputWithLabel from "../Form/Input/InputWithLabel";
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import WorkingHoursDisplay from "../Form/WorkingHoursDisplay";
import WorkingHoursEditor from "../Form/WorkingHoursEditor";
import MultiSelectInColumnWithLabel from '../../components/MultiSelects/MultiSelectInColumnWithLabel';
import InputSelect from '../Form/Input/InputSelect';
import api from '../../api';
import Cookies from 'js-cookie';

const CreateVendorProfile = ({ show, onClose, userId }) => {
    const currentYear = new Date().getFullYear();

    // Инициализация состояния формы
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        foundation_year: '',
        phone_number: '',
        web_site: '',
        description: '',
        social_media: [],
        additional_phones: [],
        address: {
            country_id: '1',
            state_id: '',
            city_id: '',
            zip_code_id: '',
            street_address: '',
        },
        free_consultation: false,
        working_hours: [],
        status: 'inactive',
    });

    // Состояния для ошибок
    const [errors, setErrors] = useState({});

    // Дополнительные состояния
    const [platforms, setPlatforms] = useState([]);
    const [contactMethods, setContactMethods] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [zipCodes, setZipCodes] = useState([]);

    const [showAddSocialPopup, setShowAddSocialPopup] = useState(false);
    const [newSocialMedia, setNewSocialMedia] = useState({ platform_id: '', url: '' });
    const [showAddPhonePopup, setShowAddPhonePopup] = useState(false);
    const [newPhone, setNewPhone] = useState({ phone_type_id: '', phone_number: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showEditWorkingHoursPopup, setShowEditWorkingHoursPopup] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [billingPeriod, setBillingPeriod] = useState('annually');

    // Состояния для типов сервисов и тарифов
    const [serviceTypes, setServiceTypes] = useState([]);
    const [selectedServiceTypeId, setSelectedServiceTypeId] = useState('');
    const [tariffServices, setTariffServices] = useState([]);
    const [selectedTariffServiceId, setSelectedTariffServiceId] = useState('');
    const [selectedTariffService, setSelectedTariffService] = useState(null);

    // Состояние для vendorId
    const [vendorId, setVendorId] = useState(null);

    // Функция для переключения периода биллинга
    const handleBillingToggle = () => {
        setBillingPeriod((prev) => (prev === 'monthly' ? 'annually' : 'monthly'));
    };

    // Функция для получения конфигурации запроса с CSRF и токеном
    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');

        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,
            },
            withCredentials: true,
        };
    };

    // Загрузка платформ при монтировании компонента
    useEffect(() => {
        const fetchPlatforms = async () => {
            try {
                const response = await api.get('/platforms/');
                setPlatforms(response.data);
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };
        fetchPlatforms();
    }, []);

    // Загрузка типов сервисов при монтировании компонента
    useEffect(() => {
        const fetchServiceTypes = async () => {
            try {
                const response = await api.get('/service-types/');
                setServiceTypes(response.data);
            } catch (error) {
                console.error('Error fetching service types:', error);
            }
        };
        fetchServiceTypes();
    }, []);

    // Загрузка тарифов при изменении выбранного типа сервиса
    useEffect(() => {
        if (selectedServiceTypeId) {
            const fetchTariffServices = async () => {
                try {
                    const response = await api.get(`/tariff-services/?service_type_id=${selectedServiceTypeId}`);
                    const fetchedTariffs = response.data;
                    console.log("Fetched tariff services:", response.data);

                    // Фильтруем тарифы со статусом 'active'
                    const activeTariffs = fetchedTariffs.filter(tariff => tariff.status === 'active');
                    console.log("Active tariffs:", activeTariffs);

                    setTariffServices(fetchedTariffs);

                    if (activeTariffs.length > 0) {
                        // Выбираем первый активный тариф
                        const firstActiveTariff = activeTariffs[0];
                        setSelectedTariffServiceId(firstActiveTariff.tariff_service_id);
                        setSelectedTariffService(firstActiveTariff);
                    } else {
                        // Если нет активных тарифов, оставляем выбор пустым
                        setSelectedTariffServiceId('');
                        setSelectedTariffService(null);
                    }
                } catch (error) {
                    console.error('Error fetching tariff services:', error);
                }
            };
            fetchTariffServices();
        } else {
            setTariffServices([]);
            setSelectedTariffServiceId('');
            setSelectedTariffService(null);
        }
    }, [selectedServiceTypeId]);

    // Обработчик изменения типа сервиса
    const handleServiceTypeChange = (e) => {
        const serviceTypeId = e.target.value;
        console.log("Selected service type ID:", serviceTypeId);
        setSelectedServiceTypeId(serviceTypeId);
        setSelectedTariffServiceId('');
        setSelectedTariffService(null);
    };

    // Загрузка методов контактов при монтировании компонента
    useEffect(() => {
        const fetchContactMethods = async () => {
            try {
                const response = await api.get('/contact-methods/');
                setContactMethods(response.data);
            } catch (error) {
                console.error('Error fetching contact methods:', error);
            }
        };
        fetchContactMethods();
    }, []);

    // Автоматическая загрузка штатов для country_id: '1' при монтировании компонента
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await api.get(`/states-filtered/?country_id=1`);
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };
        fetchStates();
    }, []);

    // Обработка изменений в основной части формы
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Обработка изменений в адресной части формы
    const handleAddressChange = async (name, value) => {
        const newValue = value;

        setFormData((prevData) => ({
            ...prevData,
            address: {
                ...prevData.address,
                [name]: newValue
            }
        }));

        // Обработка зависимостей для адреса
        if (name === 'state_id') {
            if (newValue) {
                fetchCities(newValue);
            }
            setZipCodes([]);
            setFormData((prevData) => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    city_id: '',
                    zip_code_id: '',
                    street_address: '',
                }
            }));
        }

        if (name === 'city_id') {
            if (newValue) {
                fetchZipCodes(newValue);
            }
            setFormData((prevData) => ({
                ...prevData,
                address: {
                    ...prevData.address,
                    zip_code_id: '',
                    street_address: '',
                }
            }));
        }

        if (name === 'zip_code_id') {
            // Дополнительная логика при выборе ZIP Code, если необходимо
        }
    };

    // Загрузка городов на основе выбранного штата
    const fetchCities = async (state_id) => {
        try {
            const response = await api.get(`/cities-filtered/?state_id=${state_id}`);
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    // Загрузка почтовых индексов на основе выбранного города
    const fetchZipCodes = async (city_id) => {
        try {
            const response = await api.get(`/zip-codes/?city_id=${city_id}`);
            setZipCodes(response.data);
        } catch (error) {
            console.error('Error fetching zip codes:', error);
        }
    };

    // Обработка добавления социальной сети
    const handleAddSocialMedia = () => {
        const selectedPlatform = platforms.find(platform => platform.platform_id === Number(newSocialMedia.platform_id));

        if (selectedPlatform && newSocialMedia.url) {
            const updatedSocialMedia = [
                ...formData.social_media,
                {
                    platform_id: selectedPlatform.platform_id,
                    platform_name: selectedPlatform.name,
                    url: newSocialMedia.url,
                    social_media_id: null
                }
            ];

            setFormData((prevData) => ({
                ...prevData,
                social_media: updatedSocialMedia
            }));

            setNewSocialMedia({ platform_id: '', url: '' });
            setShowAddSocialPopup(false);
        } else {
            console.log("Platform or URL not selected");
        }
    };

    // Обработка удаления социальной сети
    const handleDeleteSocialMedia = (index) => {
        const updatedSocialMedia = formData.social_media.filter((_, i) => i !== index);

        setFormData((prevData) => ({
            ...prevData,
            social_media: updatedSocialMedia
        }));
    };

    // Обработка добавления дополнительного телефона
    const handleAddPhone = () => {
        if (newPhone.phone_type_id && newPhone.phone_number) {
            const selectedPhoneType = contactMethods.find(method => method.value[1] === Number(newPhone.phone_type_id));

            if (!selectedPhoneType) {
                console.error('Selected phone type not found');
                return;
            }

            const updatedPhones = [
                ...formData.additional_phones,
                {
                    phone_type_id: Number(newPhone.phone_type_id),
                    phone_type: selectedPhoneType.name,
                    phone_number: newPhone.phone_number
                }
            ];

            setFormData((prevData) => ({
                ...prevData,
                additional_phones: updatedPhones
            }));

            setNewPhone({ phone_type_id: '', phone_number: '' });
            setShowAddPhonePopup(false);
        } else {
            console.log('Phone type and phone number are required');
        }
    };

    // Валидация формы
    const validateForm = useCallback(() => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Vendor name is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        }

        if (!formData.foundation_year) {
            newErrors.foundation_year = 'Foundation year is required';
        }

        if (!formData.phone_number) {
            newErrors.phone_number = 'Phone number is required';
        }

        if (formData.description.length < 300) {
            newErrors.description = 'Description must be at least 300 characters';
        }

        // Проверка на наличие языка (можно убрать, если не используется)
        if (formData.languages && !formData.languages.every(lang => lang.language_id)) {
            newErrors.languages = 'Each language must have a valid ID.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            console.log('Validation errors:', newErrors);
        }

        return Object.keys(newErrors).length === 0;
    }, [formData]);

    // Обработка сохранения изменений (обновление существующего поставщика)
    const handleSaveChanges = async () => {
        // Проверяем, есть ли vendorId для обновления
        if (!vendorId) {
            setErrors({ general: 'Cannot save changes without a vendor ID. Please create a vendor first.' });
            return;
        }

        // Валидация формы перед сохранением
        if (!validateForm()) {
            console.log("Validation failed");
            return;
        }

        setIsLoading(true); // Устанавливаем состояние загрузки

        // Подготовка данных для обновления
        const updateData = {
            ...formData,
            status: 'inactive', // Устанавливаем статус как 'inactive' для финального сохранения
            user_id: userId, // Добавляем user_id, полученный из пропсов
            service_type_id: selectedServiceTypeId, // Добавляем выбранный тип сервиса
            tariff_services: selectedTariffServiceId ? [{
                tariff_service_id: selectedTariffServiceId,
                billing_period: billingPeriod
            }] : [], // Добавляем выбранные тарифы, если они есть
        };

        // Используем эндпоинт для обновления существующего поставщика
        try {
            // Отправляем PUT-запрос на сервер для обновления поставщика
            const response = await api.put(`/vendor/${vendorId}/update/`, updateData, getRequestConfig());

            if (response.status === 200) {
                console.log("Changes updated successfully");
                onClose();
            } else {
                console.error('Error updating changes:', response);
                setErrors({ general: 'Error updating changes. Please try again.' });
            }
        } catch (error) {
            console.error('Error updating changes:', error.response ? error.response.data : error.message);
            setErrors({ general: 'An unexpected error occurred while updating changes. Please try again.' });
        } finally {
            setIsLoading(false); // Снимаем состояние загрузки
        }
    };

    // Обработка сабмита формы при создании Vendor
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('handleSubmit called');

        if (!validateForm()) {
            console.log("Validation failed");
            return;
        }

        setIsLoading(true);

        const submissionData = {
            name: formData.name,
            email: formData.email,
            foundation_year: formData.foundation_year,
            phone_number: formData.phone_number,
            web_site: formData.web_site,
            description: formData.description,
            service_type_id: selectedServiceTypeId,
            tariff_services: selectedTariffServiceId ? [{
                tariff_service_id: selectedTariffServiceId,
                billing_period: billingPeriod
            }] : [],
            user_id: userId,
            address: formData.address,
            social_media: formData.social_media.map(sm => ({
                platform_id: sm.platform_id,
                url: sm.url
            })),
            additional_phones: formData.additional_phones.map(phone => ({
                phone_type_id: phone.phone_type_id,
                phone_number: phone.phone_number
            })),
            working_hours: formData.working_hours.map(wh => ({
                day_of_week: wh.day_of_week,
                start_time: wh.start_time,
                end_time: wh.end_time
            })),
            service_subtypes: formData.service_subtypes // Предполагается, что это массив ID подтипов
        };

        console.log("Data to send:", submissionData);

        try {
            const response = await api.post('/vendors/create/', submissionData, getRequestConfig());

            if (response.status === 201) {
                console.log("Vendor created successfully");
                setIsSubmitted(true);
                setVendorId(response.data.vendor_id); // Предполагается, что API возвращает vendor_id
            } else {
                console.error('Error creating vendor:', response);
                setErrors({ general: 'Error creating vendor. Please try again.' });
            }
        } catch (error) {
            console.error('Error creating vendor:', error.response ? error.response.data : error.message);
            setErrors({ general: 'An unexpected error occurred. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    };

    // Обработка отправки на утверждение
    const handleSendToApproval = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            console.log("Validation failed");
            return;
        }

        // Проверяем, есть ли vendorId для отправки на утверждение
        if (!vendorId) {
            setErrors({ general: 'Cannot submit for approval without a vendor ID. Please create a vendor first.' });
            return;
        }

        try {
            const response = await api.post(`/vendors/${vendorId}/submit-for-approval/`, {
                tariff_service_id: selectedTariffServiceId, // Используем состояние напрямую
                billing_period: billingPeriod, // Используем состояние напрямую
            }, getRequestConfig());

            if (response.data.checkout_url) {
                // Перенаправляем пользователя на Stripe Checkout
                window.location.href = response.data.checkout_url;
            } else {
                setErrors({ general: 'Не удалось создать платежную сессию.' });
            }
        } catch (error) {
            console.error('Error submitting for approval:', error.response ? error.response.data : error.message);
            setErrors({ general: 'An unexpected error occurred. Please try again.' });
        }
    };

    // Отключение отправки формы при нажатии Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    // Если модальное окно не показывается, ничего не рендерим
    if (!show) return null;

    return (
        <div className="modal visible" id="create-vendor-profile">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close" />
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                        <h2>{vendorId ? 'Edit Vendor Profile' : 'Create Vendor Profile'}</h2>

                        {/* Ошибки общего характера */}
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        <InputWithLabel
                            label='Vendor Name'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            error={errors.name}
                        />

                        {/* Выбор типа сервиса */}
                        <label className="form-label">Service Type</label>
                        <select
                            name="service_type_id"
                            value={selectedServiceTypeId}
                            onChange={handleServiceTypeChange}
                            className="input"
                        >
                            <option value="">Select Service Type</option>
                            {serviceTypes.map((type) => (
                                <option key={type.service_type_id} value={type.service_type_id}>
                                    {type.service_type_name}
                                </option>
                            ))}
                        </select>

                        {/* Поле Год основания */}
                        <InputWithLabel
                            label='Foundation Year'
                            name='foundation_year'
                            type='number'
                            value={formData.foundation_year}
                            onChange={handleChange}
                            min="1800"
                            max={currentYear}
                            error={errors.foundation_year}
                        />

                        {/* Поле Электронная почта */}
                        <InputWithLabel
                            label='Email'
                            name='email'
                            type='email'
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        {/* Поле Номер телефона */}
                        <InputWithLabel
                            label='Phone Number'
                            name='phone_number'
                            type='tel'
                            value={formData.phone_number}
                            onChange={handleChange}
                            error={errors.phone_number}
                        />

                        {/* Поле Веб-сайт */}
                        <InputWithLabel
                            label='Website'
                            name='web_site'
                            type='url'
                            value={formData.web_site}
                            onChange={handleChange}
                        />

                        {/* Поле Описание */}
                        <TextareaWithLabel
                            label='Description'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            error={errors.description}
                        />

                        <span>Address</span>
                        {/* Поля для адреса */}

                        {/* Поле Штат */}
                        <select
                            className="input"
                            value={formData.address.state_id}
                            onChange={(e) => handleAddressChange('state_id', e.target.value)}
                        >
                            <option value="" disabled>Select State</option>
                            {states.map((state) => (
                                <option key={state.state_id} value={state.state_id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>

                        {/* Поле Город */}
                        {formData.address.state_id && (
                            <select
                                className="input"
                                value={formData.address.city_id}
                                onChange={(e) => handleAddressChange('city_id', e.target.value)}
                            >
                                <option value="" disabled>Select City</option>
                                {cities.map((city) => (
                                    <option key={city.city_id} value={city.city_id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        {/* Поле Почтовый индекс */}
                        {formData.address.city_id && (
                            <select
                                className="input"
                                value={formData.address.zip_code_id}
                                onChange={(e) => handleAddressChange('zip_code_id', e.target.value)}
                            >
                                <option value="" disabled>Select Zip Code</option>
                                {zipCodes.map((zip) => (
                                    <option key={zip.id} value={zip.id}>
                                        {zip.zip_code}
                                    </option>
                                ))}
                            </select>
                        )}

                        {/* Поле Улица и номер дома */}
                        {formData.address.zip_code_id && (
                            <InputWithLabel
                                label='Street and House Number'
                                name='street_address'
                                value={formData.address.street_address}
                                onChange={(e) => handleAddressChange('street_address', e.target.value)}
                                error={errors.street_address}
                            />
                        )}

                        {/* Социальные сети */}
                        {/* Здесь можно добавить компоненты для управления социальными сетями */}

                        {/* Кнопки отправки формы */}
                        {!isSubmitted ? (
                            <button
                                className="button button-black create-vendor-button"
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Creating Vendor...' : 'Create Vendor'}
                            </button>
                        ) : (
                            <>
                                {/* Переключатель для биллинга */}
                                <div className="billing-toggle-container">
                                    <div className="billing-toggle">
                                        <span
                                            className={`billing-text ${billingPeriod === 'monthly' ? 'active' : 'inactive'}`}
                                        >
                                            Monthly
                                        </span>
                                        <div
                                            className={`toggle ${billingPeriod === 'annually' ? 'checked' : ''}`}
                                            onClick={handleBillingToggle}
                                            role="switch"
                                            aria-checked={billingPeriod === 'annually'}
                                            tabIndex={0}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    handleBillingToggle();
                                                }
                                            }}
                                        >
                                            <div className="toggle__container">
                                                <div className="toggle__circle"></div>
                                            </div>
                                        </div>
                                        <span
                                            className={`billing-text ${billingPeriod === 'annually' ? 'active' : 'inactive'}`}
                                        >
                                            Annually
                                        </span>
                                    </div>
                                    <div className="billing-prices">
                                        {selectedTariffService ? (
                                            billingPeriod === 'monthly' ? (
                                                <span className="billing-price active">
                                                    ${selectedTariffService.monthly_price}/month
                                                </span>
                                            ) : (
                                                <>
                                                    <span className="billing-price inactive">
                                                        ${selectedTariffService.monthly_price}
                                                    </span>
                                                    <span className="billing-price active">
                                                        ${(selectedTariffService.annual_price / 12).toFixed(0)}/month
                                                    </span>
                                                </>
                                            )
                                        ) : (
                                            <span className="billing-price">
                                                Please select a service type
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="button-row">
                                    <button
                                        className="button button-border save-draft-button"
                                        type="button"
                                        onClick={handleSaveChanges} // Функция обновления изменений
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Saving...' : 'Save Draft'}
                                    </button>
                                    <button
                                        className="button button-black create-profile-button"
                                        type="button"
                                        onClick={handleSendToApproval}
                                        disabled={isLoading}
                                    >
                                        Submit for Approval
                                    </button>
                                </div>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateVendorProfile;
