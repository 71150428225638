import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../api';
import { SearchContext } from '../../context/SearchContext';

export const useSearchContext = () => {
    return useContext(SearchContext);
};

const HeaderBanner = ({ isBannerMinimized, isPopupActive, isPopupAttorneyActive }) => {
    const location = useLocation();
    const [bannerData, setBannerData] = useState({
        eventTitle: 'Annual California Advocates Forum',
        eventDescription: 'Network with top lawyers, attend insightful sessions, and stay updated on the latest legal trends. Don’t miss this opportunity to advance your career!',
        eventDate: 'August 24-29, 2024',
        registrationButtonText: 'Register Now',
        registrationButtonUrl: '#',
        backgroundImage: '',
        eventTitleIcon: '',
        squareImage: '',
        backgroundGradientEnabled: true,
        backgroundGradientColor: 'rgba(44, 65, 87, 0.7)',
    });

    const [isMinimize, setIsMinimize] = useState(false);
    const bannerMin = useRef(null);
    const { bannerHeight, setBannerHeight } = useSearchContext(SearchContext);
    const [minBannerStyle, setMinBannerStyle] = useState({
        backgroundImage: '',
        minHeight: '0',
    });
    const [isUserOpened, setIsUserOpened] = useState(false);
    const [isAutoOpened, setIsAutoOpened] = useState(false);
    const [isScrolledDown, setIsScrolledDown] = useState(false);
    const hasCompanyIdOrAttorneyId = location.search.includes('companyId') || location.search.includes('attorneyId');
    const isSignInPage = location.pathname.includes('sign-in'); // Проверка, является ли текущая страница страницей входа
    const isSignUpPage = location.pathname.includes('sign-up'); // Проверка, является ли текущая страница страницей Sign Up
    const isProfilePage = location.pathname.includes('profile'); // Проверка, является ли текущая страница страницей входа
    const isForgotPass = window.location.pathname.includes('forgot-password');
    const isResetPass = window.location.pathname.includes('reset-password');

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setBannerHeight(entry.contentRect.height);
                document.querySelector('.header__banner').style.minHeight = entry.contentRect.height + 'px';
            });
        });

        let banner = bannerMin.current;
        observer.observe(banner);

        return () => observer.disconnect();
    }, [setBannerHeight, bannerHeight]);

    useEffect(() => {
        setIsMinimize(isBannerMinimized);
    }, [isBannerMinimized]);

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('/bio') || currentPath.includes('/law-firm') || currentPath.includes('/vendor') || currentPath.includes('/')) {
            setIsMinimize(false);
            setIsAutoOpened(true);
        }
    }, [location.pathname]);

    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const response = await api.get('/event-banner/');
                const registrationUrl = response.data.registration_button_url.startsWith('http://') || response.data.registration_button_url.startsWith('https://')
                    ? response.data.registration_button_url
                    : `${process.env.REACT_APP_BASE_URL}${response.data.registration_button_url}`;

                setBannerData({
                    eventTitle: response.data.event_title_text || 'Annual California Advocates Forum',
                    eventDescription: response.data.event_description_text || 'Network with top lawyers, attend insightful sessions, and stay updated on the latest legal trends. Don’t miss this opportunity to advance your career!',
                    eventDate: response.data.event_date_text || 'August 24-29, 2024',
                    registrationButtonText: response.data.registration_button_text || 'Register Now',
                    registrationButtonUrl: registrationUrl,
                    backgroundImage: `${process.env.REACT_APP_BASE_URL}${response.data.background_image}` || '',
                    eventTitleIcon: `${process.env.REACT_APP_BASE_URL}${response.data.event_title_icon}` || '',
                    squareImage: `${process.env.REACT_APP_BASE_URL}${response.data.square_image}` || '',
                    backgroundGradientEnabled: response.data.background_gradient_enabled,
                    backgroundGradientColor: response.data.background_gradient_color,
                });

                setMinBannerStyle({
                    backgroundImage: response.data.background_gradient_enabled
                        ? `linear-gradient(${response.data.background_gradient_color}, ${response.data.background_gradient_color}), url(${process.env.REACT_APP_BASE_URL}${response.data.background_image})`
                        : `url(${process.env.REACT_APP_BASE_URL}${response.data.background_image})`,
                    minHeight: bannerMin.current.offsetHeight + 'px',
                });
            } catch (error) {
                console.error('Error fetching banner data:', error);
            }
        };

        fetchBannerData();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if ((isUserOpened || isAutoOpened) && !isMinimize) {
                setIsMinimize(true);
            }

            setIsScrolledDown(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isUserOpened, isAutoOpened, isMinimize]);

    useEffect(() => {
        if (isPopupActive || isPopupAttorneyActive) {
            setIsMinimize(true);
        }
    }, [isPopupActive, isPopupAttorneyActive]);

    useEffect(() => {
        if (hasCompanyIdOrAttorneyId) {
            setIsMinimize(true);
        }
    }, [hasCompanyIdOrAttorneyId]);

    // Добавляем минимизацию баннера при загрузке страницы sign-in
    useEffect(() => {
        if (isSignInPage || isProfilePage || isForgotPass || isResetPass || isSignUpPage) {
            setIsMinimize(true);
        }
    }, [isSignInPage, isSignUpPage, isProfilePage, isForgotPass, isResetPass]);


    const handleBannerClick = useCallback(() => {
        if (!isPopupActive && !isPopupAttorneyActive) {
            setIsMinimize(false);
            setIsUserOpened(true);
            setIsAutoOpened(false);
        }
    }, [isPopupActive, isPopupAttorneyActive]);

    return (
        <div
            className={`header__banner${isMinimize ? ' min' : ''}${isPopupActive || isPopupAttorneyActive ? ' no-pointer' : ''}`}
            style={minBannerStyle}
        >
            <div
                className="banner banner-min"
                onClick={!isMinimize || !isScrolledDown ? handleBannerClick : undefined}
                style={{ cursor: isMinimize && isScrolledDown ? 'default' : 'pointer' }}
            >
                <div ref={bannerMin} className="container">
                    <div className="content">
                        <ul className="banner__list">
                            <li className="banner__title">
                                <img src={bannerData.eventTitleIcon || '/assets/icon--shield-CaJ0vjNd.svg'} alt="Shield Icon" />
                                <span>{bannerData.eventTitle}</span>
                            </li>
                            <li className="banner__date">{bannerData.eventDate}</li>
                            <li className="banner__link">
                                <a href={bannerData.registrationButtonUrl} onClick={(e) => e.stopPropagation()}>
                                    {bannerData.registrationButtonText} →
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="banner banner-max">
                <button className="button-close" onClick={() => setIsMinimize(true)}>
                    <img src="assets/icon--close-CN6QlgAA.svg" alt="Close Icon" />
                </button>
                <div className="container">
                    <div className="content">
                        <div className="banner-max__logo">
                            <picture>
                                <source type="image/webp" srcSet={bannerData.squareImage} />
                                <source type="image/png" srcSet={bannerData.squareImage} />
                                <img srcSet={bannerData.squareImage} alt="Partner Logo" />
                            </picture>
                        </div>
                        <div className="banner-max__text">
                            <div>
                                <div className="banner__title">
                                    <img src={bannerData.eventTitleIcon || 'assets/icon--shield-CaJ0vjNd.svg'} alt="Shield Icon" />
                                    <span>{bannerData.eventTitle}</span>
                                </div>
                                <p className="banner__description">{bannerData.eventDescription}</p>
                            </div>
                            <ul className="banner__list">
                                <li>
                                    <div className="banner__date">{bannerData.eventDate}</div>
                                </li>
                                <li>
                                    <a
                                        className="banner__link"
                                        href={bannerData.registrationButtonUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {bannerData.registrationButtonText} →
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderBanner;
