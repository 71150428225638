import React, {useEffect, useState, useCallback, useRef, useMemo} from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {useUser} from "../../context/UserContext";
import api from '../../api';
import LawCompanyWithImage from '../LawCompanyWithImage';
import Pagination from "../pagination/Pagination";
import '../pagination/pagination.css';
import VendorWithImage from "../Vendors/VendorWithImage";
import AttorneyWithImage from "../AttorneyWithImage";
import {useLocation, useNavigate} from "react-router-dom";
import LogoutModal from '../popups/LogoutModal';
import {useNotification} from '../../context/NotificationContext';
import CreateVendorProfile from "../popups/CreateVendorProfile"; // Импортируем хук уведомлений
import Cookies from 'js-cookie';
import CreateLawCompanyProfile from "../popups/CreateLawCompanyProfile";
import BillingInformation from './BillingInformation';
import GetPro from "./GetPro";
import ManageLegalCompaniesPopup from "./ManageLegalCompaniesPopup";
import PushNotificationToggle from "../Form/Input/PushNotificationToggle";

const UserProfile = ({onShowPopup}) => {
    const {user, logout, shouldOpenCreateVendorPopup, resetCreateVendorFlag} = useUser();
    const navigate = useNavigate(); // Хук для навигации
    const location = useLocation(); // Хук для доступа к локации
    const [activeTab, setActiveTab] = useState(0);
    const [activeMyTab, setActiveMyTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paginatedCompanies, setPaginatedCompanies] = useState([]); // Favorite Law Firm
    const [paginatedMyCompanies, setPaginatedMyCompanies] = useState([]); // My Law Firm
    const [paginatedVendorCompanies, setPaginatedVendorCompanies] = useState([]); // Favorite Vendor
    const [paginatedMyVendorCompanies, setPaginatedMyVendorCompanies] = useState([]); // My Vendor
    const [paginatedAttorneyCompanies, setPaginatedAttorneyCompanies] = useState([]); // Favorite Attorney
    const [paginatedMyAttorneyCompanies, setPaginatedMyAttorneyCompanies] = useState([]); // My Attorney
    const [currentPage, setCurrentPage] = useState(1); // Favorite Law Firm
    const [currentMyPage, setCurrentMyPage] = useState(1); // My Law Firm
    const [currentVendorPage, setCurrentVendorPage] = useState(1); // Favorite Vendor
    const [currentMyVendorPage, setCurrentMyVendorPage] = useState(1); // My Vendor
    const [currentAttorneyPage, setCurrentAttorneyPage] = useState(1); // Favorite Attorney
    const [currentMyAttorneyPage, setCurrentMyAttorneyPage] = useState(1); // My Attorney
    const [totalCompanies, setTotalCompanies] = useState(0); // Favorite Law Firm
    const [totalMyCompanies, setTotalMyCompanies] = useState(0); // My Law Firm
    const [totalVendorCompanies, setTotalVendorCompanies] = useState(0); //Favorite Vendor
    const [totalMyVendorCompanies, setTotalMyVendorCompanies] = useState(0); // My Vendor
    const [totalAttorneyCompanies, setTotalAttorneyCompanies] = useState(0); // Favorite Attorney
    const [totalMyAttorneyCompanies, setTotalMyAttorneyCompanies] = useState(0); // My Attorney
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const companiesPerPage = 3;
    const {showNotification} = useNotification(); // Получаем только функцию showNotification
    const [showCreateVendorPopup, setShowCreateVendorPopup] = useState(false);
    const [errors, setErrors] = useState({});
    const [showCreateLawCompanyPopup, setShowCreateLawCompanyPopup] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [subscriptionsError, setSubscriptionsError] = useState(null);
    const [visibleSubscriptions, setVisibleSubscriptions] = useState(3);
    const [isManageLegalCompaniesOpen, setIsManageLegalCompaniesOpen] = useState(false);

    const loadMoreSubscriptions = () => {
        setVisibleSubscriptions((prev) => prev + 3);
    };

    const fetchActiveSubscriptions = useCallback(async () => {
        setLoadingSubscriptions(true);
        setSubscriptionsError(null);
        try {
            const response = await api.get('/active-subscriptions/');
            setActiveSubscriptions(response.data);
            console.log("Полученные активные подписки:", response.data);
        } catch (error) {
            console.error('Ошибка при получении активных подписок:', error);
            setSubscriptionsError('No subscriptions.');
        } finally {
            setLoadingSubscriptions(false);
        }
    }, []);
    const handleLogoutClick = () => {
        setShowLogoutModal(true);
        console.log('handleLogoutClick called, showLogoutModal set to:', true);
    };

    // Функция для извлечения параметров из строки запроса
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };


    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');
        const vendorId = query.get('vendor_id');
        const status = query.get('status'); // 'success' или 'cancel'

        if (status === 'success') {
            if (sessionId && vendorId) {
                // Поскольку статусы уже обновлены через вебхуки,
                // просто отображаем уведомление о успешной оплате
                showNotification(
                    'Subscription successfully completed!',
                    'Your subscription has been activated.',
                    null,
                    'success'
                );
            } else {
                showNotification(
                    'Payment completed.',
                    'Your payment was successful.',
                    null,
                    'success'
                );
            }

            // Очистка параметров URL после обработки
            navigate('/profile', {replace: true});
        } else if (status === 'cancel') {
            showNotification(
                'Subscription cancelled.',
                'You have cancelled the subscription process.',
                null,
                'warning'
            );

            // Очистка параметров URL после обработки
            navigate('/profile', {replace: true});
        }
    }, [location.search, navigate, showNotification]);

    // Дополнительный лог для проверки итогового массива активных подписок
    useEffect(() => {
        console.log("Итоговые активные подписки:", activeSubscriptions);
    }, [activeSubscriptions]);

    const handleCreateVendorProfile = () => {
        setShowCreateVendorPopup(true);
    };
    // Обработчик открытия модального окна создания юридической компании
    const handleCreateLawCompanyProfile = () => {
        setShowCreateLawCompanyPopup(true);
    };

    // Обработчик закрытия модального окна создания юридической компании
    const handleCloseCreateLawCompanyPopup = () => {
        setShowCreateLawCompanyPopup(false);
    };


    const handleCloseLogoutModal = () => {
        setShowLogoutModal(false);
    };

    const handleLogout = () => {
        logout(); // Вызываем функцию logout
        navigate('/sign-in'); // Перенаправляем пользователя на страницу входа
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleMyTabClick = (index) => {
        setActiveMyTab(index);
    };

    useEffect(() => {
        if (shouldOpenCreateVendorPopup) {
            setShowCreateVendorPopup(true);
            resetCreateVendorFlag();
        }
    }, [shouldOpenCreateVendorPopup, resetCreateVendorFlag]);

    useEffect(() => {
        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/favorite-legal-companies/?page=${currentPage}`);
                const results = response.data.results;

                setPaginatedCompanies(results); // Заменяем текущие компании на новые
                setTotalCompanies(response.data.count); // Сохраняем общее количество компаний
            } catch (error) {
                console.error('Error fetching companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, [currentPage]);

    useEffect(() => {
        const fetchMyCompanies = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/user-legal-companies/?page=${currentMyPage}`);
                const results = response.data.results;

                setPaginatedMyCompanies(results); // Заменяем текущие компании на новые
                setTotalMyCompanies(response.data.count); // Сохраняем общее количество компаний
            } catch (error) {
                console.error('Error fetching companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMyCompanies();
    }, [currentMyPage]);

    useEffect(() => {
        const fetchVendorCompanies = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/favorite-vendor-companies/?page=${currentVendorPage}`);
                const results = response.data.results;
                setPaginatedVendorCompanies(results);
                setTotalVendorCompanies(response.data.count);
            } catch (error) {
                console.error('Error fetching companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVendorCompanies();
    }, [currentVendorPage]);

    useEffect(() => {
        const fetchMyVendorCompanies = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/user-vendors/?page=${currentMyVendorPage}`);
                const results = response.data.results;
                setPaginatedMyVendorCompanies(results);
                setTotalMyVendorCompanies(response.data.count);
            } catch (error) {
                console.error('Error fetching companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMyVendorCompanies();
    }, [currentMyVendorPage]);

    useEffect(() => {
        const fetchAttorneyCompanies = async () => { // Для адвокатов
            setLoading(true);
            try {
                const response = await api.get(`/favorite-attorney-companies/?page=${currentAttorneyPage}`);
                const results = response.data.results;
                console.log('Attorneys response', results);
                setPaginatedAttorneyCompanies(results);
                setTotalAttorneyCompanies(response.data.count);
            } catch (error) {
                console.error('Error fetching attorney companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAttorneyCompanies();
    }, [currentAttorneyPage]);

    useEffect(() => {
        const fetchMyAttorneyCompanies = async () => { // Для адвокатов
            setLoading(true);
            try {
                const response = await api.get(`/user-attorneys/?page=${currentMyAttorneyPage}`);
                const results = response.data.results;
                console.log('Attorneys response', results);
                setPaginatedMyAttorneyCompanies(results);
                setTotalMyAttorneyCompanies(response.data.count);
            } catch (error) {
                console.error('Error fetching attorney companies:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMyAttorneyCompanies();
    }, [currentMyAttorneyPage]);

    const scrollToHeader = (headerId) => {
        const headerElement = document.getElementById(headerId);
        if (headerElement) {
            headerElement.scrollIntoView({behavior: 'smooth'});
        }
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber !== currentPage) {
            setCurrentPage(pageNumber); // Обновляем текущую страницу
        }
        scrollToHeader('profileHeader1'); // Прокрутка к заголовку

    };

    const handleMyPageChange = (pageNumber) => {
        if (pageNumber !== currentMyPage) {
            setCurrentMyPage(pageNumber); // Обновляем текущую страницу
        }
        scrollToHeader('profileHeader2'); // Прокрутка к заголовку

    };
    const handlePageVendorChange = (pageNumber) => {
        if (pageNumber !== currentVendorPage) {
            setCurrentVendorPage(pageNumber);
            scrollToHeader('profileHeader1'); // Прокрутка к заголовку
        }
    };
    const handleMyPageVendorChange = (pageNumber) => {
        if (pageNumber !== currentMyVendorPage) {
            setCurrentMyVendorPage(pageNumber);
            scrollToHeader('profileHeader2'); // Прокрутка к заголовку
        }
    };


    useEffect(() => {
        fetchActiveSubscriptions();
    }, [fetchActiveSubscriptions]);

    const handlePageAttorneyChange = (pageNumber) => { // Для адвокатов
        if (pageNumber !== currentAttorneyPage) {
            setCurrentAttorneyPage(pageNumber); // Обновляем текущую страницу
            scrollToHeader('profileHeader1'); // Прокрутка к заголовку
        }
    };
    const handleMyPageAttorneyChange = (pageNumber) => { // Для адвокатов
        if (pageNumber !== currentMyAttorneyPage) {
            setCurrentMyAttorneyPage(pageNumber); // Обновляем текущую страницу
            scrollToHeader('profileHeader2'); // Прокрутка к заголовку
        }
    };
    const openManageLegalCompanies = () => {
        setIsManageLegalCompaniesOpen(true);
    };

    const closeManageLegalCompanies = () => {
        setIsManageLegalCompaniesOpen(false);
    };

    const totalPagesCompanies = Math.ceil(totalCompanies / companiesPerPage);
    const totalMyPagesCompanies = Math.ceil(totalMyCompanies / companiesPerPage);
    const totalVendorPagesCompanies = Math.ceil(totalVendorCompanies / companiesPerPage);
    const totalMyVendorPagesCompanies = Math.ceil(totalMyVendorCompanies / companiesPerPage);
    const totalAttorneyPagesCompanies = Math.ceil(totalAttorneyCompanies / companiesPerPage);
    const totalMyAttorneyPagesCompanies = Math.ceil(totalMyAttorneyCompanies / companiesPerPage);

    console.log('showLogoutModal:', showLogoutModal);

    if (!user) {
        return <p>Loading...</p>;
    }

    return (
        <div className="page">
            <main className="profile">
                <div className="inner-content">
                    <div className="profile__block">
                        <div className="container">
                            <div className="userinfo">
                                <div className="userpick">
                                    <div className="userpick__img"><img src="/assets/img--no-userpick-CiM-oPiM.svg"
                                                                        alt="No User"/></div>
                                    <label className="userpick__edit">
                                        <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit Icon"/>
                                        <input type="file"/>
                                    </label>
                                </div>
                                <div className="profile__header">
                                    <div className="vendor__header"><h1
                                        className="vendor__name">{user.first_name} {user.last_name}</h1>
                                    </div>
                                    <button className="no-style">
                                        <img src="/assets/icon--edit-Z9QfuMgm.svg" alt="Edit Icon"/> Edit profile
                                    </button>
                                </div>
                            </div>
                            <div className="userdata">
                                <div className="data">
                                    <span className="no-description">Add more information about yourself</span>
                                    <button className="button button-border">Add Information</button>
                                </div>
                                <ul className="contacts__list">
                                    <li className="contacts__el">
                                        <div>
                                            <span className="label">Email</span>
                                            <input
                                                className="value mail"
                                                type="text"
                                                value={user.email || ''}
                                                readOnly // Устанавливаем input как только для чтения, если не планируется редактирование
                                            />
                                            <button className="confirm" type="button">Confirm</button>
                                        </div>
                                        <button className="no-style js--show-notification">
                                            <img src="/assets/icon--eye-off-BC-1ptB2.svg" alt="Eye Off Icon"/>
                                        </button>
                                    </li>
                                    <li className="contacts__el">
                                        <div>
                                            <span className="label">Phone</span>
                                            <input className="value phone" type="text" value="+1 415-555-0202"
                                                   readOnly/>
                                            <button className="confirm hidden" type="button">Confirm</button>
                                        </div>
                                        <button className="no-style js--show-notification">
                                            <img src="/assets/icon--eye-off-BC-1ptB2.svg" alt="Eye Off Icon"/>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="profile__block">
                        <div id="profileHeader1" className="container">
                            <div className="profile__header"><h2>My Favourite</h2>
                                {/*<span>0 added</span>*/}
                            </div>
                        </div>
                        <div className="profile__content">
                            <div className="tabs">
                                <div className="container">
                                    <ul className="tabs__caption">
                                        <li
                                            className={activeTab === 0 ? "active" : ""}
                                            onClick={() => handleTabClick(0)}
                                        >
                                            Law Companies
                                        </li>
                                        <li
                                            className={activeTab === 1 ? "active" : ""}
                                            onClick={() => handleTabClick(1)}
                                        >
                                            Attorneys
                                        </li>
                                        <li
                                            className={activeTab === 2 ? "active" : ""}
                                            onClick={() => handleTabClick(2)}
                                        >
                                            Vendors
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className='tabs__content-wrapper'>
                                    {/*className={`tabs__content-wrapper ${totalPagesCompanies > 1 ? 'with-pagination' : ''}`}>*/}
                                    <div className={activeTab === 0 ? "tabs__content active" : "tabs__content"}>
                                        {/*<TransitionGroup component={null}>*/}
                                        {/*    <CSSTransition*/}
                                        {/*        key={currentPage}*/}
                                        {/*        classNames="slide"*/}
                                        {/*        timeout={500}*/}
                                        {/*    >*/}
                                        <>
                                            {activeTab === 0 && (
                                                Array.isArray(paginatedCompanies) && paginatedCompanies.map((company, index) => {
                                                    const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                    const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');

                                                    return (
                                                        <LawCompanyWithImage
                                                            key={company.id}
                                                            company={company}
                                                            onShowPopup={onShowPopup}
                                                            styleClass={styleClass}
                                                        />
                                                    );
                                                })
                                            )}
                                        </>
                                        {/*</CSSTransition>*/}
                                        {/*</TransitionGroup>*/}
                                    </div>
                                    <div className={activeTab === 1 ? "tabs__content active" : "tabs__content"}>
                                        {/*<TransitionGroup component={null}>*/}
                                        {/*    <CSSTransition*/}
                                        {/*        key={currentPage}*/}
                                        {/*        classNames="slide"*/}
                                        {/*        timeout={500}*/}
                                        {/*    >*/}
                                        <>
                                            {activeTab === 1 && (
                                                Array.isArray(paginatedAttorneyCompanies) && paginatedAttorneyCompanies.map((company, index) => {
                                                    const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                    const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');

                                                    return (
                                                        <AttorneyWithImage
                                                            key={company.attorney_id}
                                                            attorney={company}
                                                            onShowPopup={onShowPopup}
                                                            styleClass={styleClass}
                                                        />
                                                    );
                                                })
                                            )}
                                        </>
                                        {/*</CSSTransition>*/}
                                        {/*</TransitionGroup>*/}
                                    </div>
                                    <div className={activeTab === 2 ? "tabs__content active" : "tabs__content"}>
                                        {/*<TransitionGroup component={null}>*/}
                                        {/*    <CSSTransition*/}
                                        {/*        key={currentPage}*/}
                                        {/*        classNames="slide"*/}
                                        {/*        timeout={500}*/}
                                        {/*    >*/}
                                        <>
                                            {activeTab === 2 && (
                                                Array.isArray(paginatedVendorCompanies) && paginatedVendorCompanies.map((company, index) => {
                                                    const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                    const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');
                                                    return (
                                                        <VendorWithImage
                                                            key={company.vendor_id}
                                                            vendor={company}
                                                            onShowPopup={onShowPopup}
                                                            styleClass={styleClass}
                                                        />
                                                    );
                                                })
                                            )}
                                        </>
                                        {/*</CSSTransition>*/}
                                        {/*</TransitionGroup>*/}
                                    </div>
                                    {/*<TransitionGroup component={null}>*/}
                                    {/*    <CSSTransition*/}
                                    {/*        key={currentPage}*/}
                                    {/*        classNames="slide"*/}
                                    {/*        timeout={500}*/}
                                    {/*    >*/}
                                    <>
                                        {totalPagesCompanies > 1 && activeTab === 0 && (
                                            <div className="container">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPagesCompanies}
                                                    onPageChange={handlePageChange}
                                                    scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп
                                                />
                                            </div>
                                        )}
                                        {totalAttorneyPagesCompanies > 1 && activeTab === 1 && (
                                            <div className="container">
                                                <Pagination
                                                    currentPage={currentAttorneyPage}
                                                    totalPages={totalAttorneyPagesCompanies}
                                                    onPageChange={handlePageAttorneyChange}
                                                    scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп

                                                />
                                            </div>
                                        )}
                                        {totalVendorPagesCompanies > 1 && activeTab === 2 && (
                                            <div className="container">
                                                <Pagination
                                                    currentPage={currentVendorPage}
                                                    totalPages={totalVendorPagesCompanies}
                                                    onPageChange={handlePageVendorChange}
                                                    scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп

                                                />
                                            </div>
                                        )}
                                    </>
                                    {/*</CSSTransition>*/}
                                    {/*</TransitionGroup>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile__block">
                        <div className="container">
                            <div id="profileHeader2" className="profile__header">
                                <h2>My Profiles</h2>
                                <div className="justify">
                                    {/*<button className="button no-style">*/}
                                    {/*    <img src="/assets/icon--plus-Dj324Jkx.svg" alt="Add New Law Firm"/>*/}
                                    {/*    <span>Add new Law Firm</span>*/}
                                    {/*</button>*/}
                                    {/*<span className="point">•</span>*/}
                                    {/*<span> 6 added</span>*/}
                                </div>
                            </div>
                        </div>
                        <div className="profile__content">
                            <div className="tabs">
                                <div className="container">
                                    <ul className="tabs__caption">
                                        <li
                                            className={activeMyTab === 0 ? "active" : ""}
                                            onClick={() => handleMyTabClick(0)}
                                        >
                                            Law Companies
                                        </li>
                                        <li
                                            className={activeMyTab === 1 ? "active" : ""}
                                            onClick={() => handleMyTabClick(1)}
                                        >
                                            Attorneys
                                        </li>
                                        <li
                                            className={activeMyTab === 2 ? "active" : ""}
                                            onClick={() => handleMyTabClick(2)}
                                        >
                                            Vendors
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className='tabs__content-wrapper'>
                                    {/*className={`tabs__content-wrapper ${totalPagesCompanies > 1 ? 'with-pagination' : ''}`}>*/}
                                    <div className={activeMyTab === 0 ? "tabs__content active" : "tabs__content"}>
                                        {activeMyTab === 0 && (
                                            Array.isArray(paginatedMyCompanies) && paginatedMyCompanies.map((company, index) => {
                                                const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');

                                                return (
                                                    <LawCompanyWithImage
                                                        key={company.id}
                                                        company={company}
                                                        onShowPopup={onShowPopup}
                                                        styleClass={styleClass}
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    <div className={activeMyTab === 1 ? "tabs__content active" : "tabs__content"}>
                                        {activeMyTab === 1 && (
                                            Array.isArray(paginatedMyAttorneyCompanies) && paginatedMyAttorneyCompanies.map((company, index) => {
                                                const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');

                                                return (
                                                    <AttorneyWithImage
                                                        key={company.attorney_id}
                                                        attorney={company}
                                                        onShowPopup={onShowPopup}
                                                        styleClass={styleClass}
                                                    />
                                                );
                                            })
                                        )}

                                    </div>
                                    <div className={activeMyTab === 2 ? "tabs__content active" : "tabs__content"}>
                                        {activeMyTab === 2 && (
                                            Array.isArray(paginatedMyVendorCompanies) && paginatedMyVendorCompanies.map((company, index) => {
                                                const hasActivePlans = company.active_plans && company.active_plans.length > 0;
                                                const styleClass = hasActivePlans ? 'vendor-day' : (Math.random() > 0.5 ? 'vendor-verified' : '');
                                                return (
                                                    <VendorWithImage
                                                        key={company.vendor_id}
                                                        vendor={company}
                                                        onShowPopup={onShowPopup}
                                                        styleClass={styleClass}
                                                    />
                                                );
                                            })
                                        )}
                                    </div>
                                    {totalMyPagesCompanies > 1 && activeMyTab === 0 && (
                                        <div className="container">
                                            <Pagination
                                                currentPage={currentMyPage}
                                                totalPages={totalMyPagesCompanies}
                                                onPageChange={handleMyPageChange}
                                                scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп

                                            />
                                        </div>
                                    )}
                                    {totalMyAttorneyPagesCompanies > 1 && activeMyTab === 1 && (
                                        <div className="container">
                                            <Pagination
                                                currentPage={currentMyAttorneyPage}
                                                totalPages={totalMyAttorneyPagesCompanies}
                                                onPageChange={handleMyPageAttorneyChange}
                                                scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп

                                            />
                                        </div>
                                    )}
                                    {totalMyVendorPagesCompanies > 1 && activeMyTab === 2 && (
                                        <div className="container">
                                            <Pagination
                                                currentPage={currentMyVendorPage}
                                                totalPages={totalMyVendorPagesCompanies}
                                                onPageChange={handleMyPageVendorChange}
                                                scrollToHeader={scrollToHeader} // Передаем функцию прокрутки как проп

                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile__block">
                        <div className="container">
                            <div className="profile__header">
                                <h2>Account Settings</h2>
                            </div>
                            <div className="profile__content">
                                <div className="col-2">
                                    <div className="settings">
                                        <div className="settings__block">
                                            <h3>Notifications:</h3>
                                            <ul className="settings__list">
                                                <li className="settings__el">
                                                    <span className="name"> Email Notifications</span>
                                                    <label className="toggle">
                                                        <input type="checkbox"/>
                                                        <div className="toggle__container">
                                                            <div className="toggle__circle"></div>
                                                        </div>
                                                    </label>
                                                </li>
                                                <li className="settings__el">
                                                    <span className="name">Push Notifications</span>
                                                    <PushNotificationToggle/> {/* Use the new toggle */}
                                                </li>
                                                <li className="settings__el">
                                                    <span className="name">SMS Notifications</span>
                                                    <label className="toggle">
                                                        <input type="checkbox"/>
                                                        <div className="toggle__container">
                                                            <div className="toggle__circle"></div>
                                                        </div>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="settings__block">
                                            <h3>Security Settings:</h3>
                                            <ul className="settings__list">
                                                <li className="settings__el">
                                                    <span className="name">Password</span>
                                                    <button className="change">
                                                        Change
                                                        <img src="/assets/icon--angle-right-_l5unWts.svg"
                                                             alt="Change Password"/>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="settings__block">
                                            {loadingSubscriptions && <p>Loading subscriptions...</p>}
                                            {subscriptionsError && <p className="error">{subscriptionsError}</p>}
                                            {!loadingSubscriptions && !subscriptionsError && (
                                                <div className="subscriptions__list">
                                                    {activeSubscriptions && activeSubscriptions.length > 0 ? (
                                                        <>
                                                            <h3>Subscriptions:</h3>
                                                            <ul className="settings__list">
                                                                {activeSubscriptions.slice(0, visibleSubscriptions).map((subscription) => (
                                                                    <li key={subscription.id} className="settings__el">
                                                                        <span
                                                                            className="name">{subscription.vendorName}</span>
                                                                        <span
                                                                            className="billing-period">${subscription.price} / {subscription.billingPeriod}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            {visibleSubscriptions < activeSubscriptions.length && (
                                                                <button className="load-more-button"
                                                                        onClick={loadMoreSubscriptions}>
                                                                    Load More
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="create-links">
                                        <button className="create-link" onClick={handleCreateVendorProfile}>
                                            <span>Create</span>
                                            <h2>Vendor Profile</h2>
                                        </button>
                                        {/* кнопка для создания профиля юридической компании */}
                                        <button className="create-link" onClick={handleCreateLawCompanyProfile}>
                                            <span>Create</span>
                                            <h2>Law Company Profile</h2>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GetPro openManageLegalCompanies={openManageLegalCompanies}/>

                    <BillingInformation/>
                    <div className="profile__block">
                        <div className="profile__block">
                            <div className="container">
                                <div className="profile__header">
                                    <h2>Exit from the My Profile</h2>
                                    {/* Кнопка "Logout now" с обработчиком клика */}
                                    <button className="no-style" onClick={handleLogoutClick}>
                                        <img src="/assets/icon--logout-CCg5Pv1d.svg" alt="Logout"/> Logout now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            <div className="popup popup-form" id="add-firm">
                <button className="close js--hide-popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="hidden error-notification">
                    <img src="/assets/icon--attantion-red-Vu3sv48b.svg" alt="Attention"/>
                    <span>Please enter all law firm details to continue</span>
                </div>
                <div className="main-form-container">
                    <div className="main-form__img"></div>
                    <div className="main-form__right">
                        <div className="main-form__header">
                            <div className="logo__text">
                                <img src="/assets/logo--text-BszNfwNg.svg" alt="Logo"/>
                            </div>
                        </div>
                        <form className="main-form__content">
                            <div className="scroll-wrap">
                                <h3>Add Law Firm</h3>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Firm name</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Address</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">City</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">State</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Post Code</span>
                                </label>
                                <select className="input">
                                    <option selected disabled>Country</option>
                                    <option value="">USA</option>
                                    <option value="">Canada</option>
                                </select>
                                <div className="separator"></div>
                                <span className="text-left">Add attorney working at the firm</span>
                                <button className="button button-border" type="button">Add attorney</button>
                                <button className="button button-black" disabled>Submit for Approval</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="popup popup-form" id="add-vendor">
                <button className="close js--hide-popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="hidden error-notification">
                    <img src="/assets/icon--attantion-red-Vu3sv48b.svg" alt="Attention"/>
                    <span>Please enter all vendor details to continue</span>
                </div>
                <div className="main-form-container">
                    <div className="main-form__img"></div>
                    <div className="main-form__right">
                        <div className="main-form__header">
                            <div className="logo__text">
                                <img src="/assets/logo--text-BszNfwNg.svg" alt="Logo"/>
                            </div>
                        </div>
                        <form className="main-form__content">
                            <div className="scroll-wrap">
                                <h3>Add Vendor</h3>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Vendor name</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Address</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">City</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">State</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Post Code</span>
                                </label>
                                <select className="input">
                                    <option selected disabled>Country</option>
                                    <option value="">USA</option>
                                    <option value="">Canada</option>
                                </select>
                                <button className="button button-black" disabled>Submit for Approval</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="popup popup-form" id="filled-attorneys">
                <button className="close js--hide-popup">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="hidden error-notification">
                    <img src="/assets/icon--attantion-red-Vu3sv48b.svg" alt="Attention"/>
                    <span>Please enter all law firm details to continue</span>
                </div>
                <div className="main-form-container">
                    <div className="main-form__img"></div>
                    <div className="main-form__right">
                        <div className="main-form__header">
                            <div className="logo__text">
                                <img src="/assets/logo--text-BszNfwNg.svg" alt="Logo"/>
                            </div>
                        </div>
                        <form className="main-form__content">
                            <div className="scroll-wrap">
                                <h3>Add Law Firm</h3>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Firm name</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Address</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">City</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">State</span>
                                </label>
                                <label className="input-label">
                                    <input className="input" type="text" name=""/>
                                    <span className="placeholder">Post Code</span>
                                </label>
                                <select className="input">
                                    <option selected disabled>Country</option>
                                    <option value="">USA</option>
                                    <option value="">Canada</option>
                                </select>
                                <div className="separator"></div>
                                <span className="text-left">Attorneys working at the firm</span>
                                <ul className="attorneys__list">
                                    <li className="attorney">
                                        <div className="attorney__avatar">
                                            <div className="avatar">
                                                <picture>
                                                    <source type="image/webp"
                                                            srcSet="/assets/avatar-CLimfe7W.webp 1x, /assets/avatar@2x-B_wrGpzH.webp 2x"/>
                                                    <source type="image/jpg"
                                                            srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"/>
                                                    <img
                                                        srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"
                                                        alt=""/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className="attorney__content">
                                            <div>
                                                <h3 className="attorney__name">Mountain, Joshua D.</h3>
                                                <div className="attorney__info">44 court hearings • Winning cases 27%
                                                </div>
                                            </div>
                                            <ul className="params__list">
                                                <li>Immigration Law</li>
                                                <li>Visa Applications</li>
                                                <li>Asylum</li>
                                            </ul>
                                        </div>
                                        <button className="button no-style">
                                            <img src="/assets/icon--delete-gray-BAOsHS3n.svg" alt="Delete"/>
                                        </button>
                                    </li>
                                    <li className="attorney">
                                        <div className="attorney__avatar">
                                            <div className="avatar">
                                                <picture>
                                                    <source type="image/webp"
                                                            srcSet="/assets/avatar-CLimfe7W.webp 1x, /assets/avatar@2x-B_wrGpzH.webp 2x"/>
                                                    <source type="image/jpg"
                                                            srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"/>
                                                    <img
                                                        srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"
                                                        alt=""/>
                                                </picture>
                                            </div>
                                        </div>
                                        <div className="attorney__content">
                                            <div>
                                                <h3 className="attorney__name">Mountain, Joshua D.</h3>
                                                <div className="attorney__info">22 court hearings • Winning cases 19%
                                                </div>
                                            </div>
                                            <ul className="params__list">
                                                <li>Immigration Law</li>
                                                <li>Visa Applications</li>
                                            </ul>
                                        </div>
                                        <button className="button no-style">
                                            <img src="/assets/icon--delete-gray-BAOsHS3n.svg" alt="Delete"/>
                                        </button>
                                    </li>
                                </ul>
                                <button className="button button-border" type="button">+ Add New attorney</button>
                                <button className="button button-black">Submit for Approval</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="delete">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <h2> Delete Attorney Profile?</h2>
                            <span className="subtitle">Delete Attorney Profile?</span>
                            <input className="input" type="text" placeholder="Enter your email"/>
                            <button className="button button-red">Yes</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="alert">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <div className="content">
                            <h2> This profile was blocked</h2>
                            <span
                                className="subtitle">If you believe this is incorrect, please contact our support team</span>
                            <button className="button button-black">Contact Support</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="exist">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <div className="content">
                            <h2> This attorney is already listed on our site</h2>
                            <span
                                className="subtitle">If you believe this is incorrect, please contact our support team</span>
                            <button className="button button-black">Contact Support</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="profile-added">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <div className="content">
                            <h2>Your profile has been added</h2>
                            <span className="subtitle">
                    After adding, the firm's profile will be reviewed and approved. You'll be notified via email.
                </span>
                            <button className="button button-black">Show My Profile</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="add-social">
                <div className="modal__overlay"></div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <h2>Add Social Network</h2>
                            <select className="input">
                                <option selected disabled>Choose name</option>
                            </select>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Add link</span>
                            </label>
                            <button className="button button-black">Add Profile</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="add-new-attorney">
                <div className="modal__overlay"></div>
                <div className="modal__info">
                    <img src="/assets/icon--info-black-B_CjCXpG.svg" alt=""/>
                    <p></p>
                    <button className="button no-style js--close-info">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt=""/>
                    </button>
                </div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <h2>Add attorney</h2>
                            <label className="input-label">
                                <input className="input filled" type="text" value="234 567" disabled/>
                                <span className="placeholder">Attorney ID</span>
                                <div className="help js--show-info" data-text="Текст подсказки 1">
                                    <img src="/assets/icon--info-8nUm4QuK.svg" alt="Info"/>
                                </div>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">First Name</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Last Name</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Email</span>
                                <div className="help js--show-info" data-text="Текст подсказки 2">
                                    <img src="/assets/icon--info-8nUm4QuK.svg" alt="Info"/>
                                </div>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Phone Number</span>
                                <div className="help js--show-info" data-text="Текст подсказки 3">
                                    <img src="/assets/icon--info-8nUm4QuK.svg" alt="Info"/>
                                </div>
                            </label>
                            <label className="input-label">
                                <select
                                    className="js--select-style input"
                                    multiple
                                    placeholder="Specialization"
                                >
                                    <option value="use">USA</option>
                                    <option value="russia">Russia</option>
                                </select>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Language</span>
                            </label>
                            <button className="button button-black">Yes</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="edit-profile">
                <div className="modal__overlay"></div>
                <div className="modal__info">
                    <img src="/assets/icon--info-black-B_CjCXpG.svg" alt=""/>
                    <p></p>
                    <button className="button no-style js--close-info">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt=""/>
                    </button>
                </div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <h2>Edit Profile</h2>
                            <label className="input-label">
                                <input className="input filled" type="text" value="Anred"/>
                                <span className="placeholder">First Name</span>
                            </label>
                            <label className="input-label">
                                <input className="input filled" type="text" value="Antar"/>
                                <span className="placeholder">Last Name</span>
                            </label>
                            <label className="input-label">
                                <input className="input filled" type="text"/>
                                <span className="placeholder">Email</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Phone Number</span>
                            </label>
                            <span>About me</span>
                            <label className="input-label">
                                <textarea className="input" type="text"></textarea>
                                <span className="placeholder">Add more information about yourself</span>
                            </label>
                            <span>Social Networks</span>
                            <label className="input-label">
                                <input className="input filled" type="text" value="LinkedIn"/>
                                <span className="placeholder">LinkedIn</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">Facebook</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text"/>
                                <span className="placeholder">X (ex. Twitter)</span>
                            </label>
                            <span className="underline">Add Other Network</span>
                            <button className="js--show-modal" data-modal="add-social" type="button">
                                Для теста, что отображается 2 уровень модалок
                            </button>
                            <button className="button button-black">Save Profile</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="edit-firm">
                <div className="modal__overlay"></div>
                <div className="modal__info">
                    <img src="/assets/icon--info-black-B_CjCXpG.svg" alt=""/>
                    <p></p>
                    <button className="button no-style js--close-info">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt=""/>
                    </button>
                </div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content">
                            <h2>Edit Law Firm</h2>
                            <label className="input-label">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">Firm name</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">Address</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">City</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">State</span>
                            </label>
                            <label className="input-label">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">Post Code</span>
                            </label>
                            <select className="input">
                                <option selected disabled>Country</option>
                                <option value="">USA</option>
                                <option value="">Canada</option>
                            </select>
                            <span>Attorneys Working in This Firm:</span>
                            <ul className="attorneys__list">
                                <li className="attorney">
                                    <div className="attorney__avatar">
                                        <div className="avatar">
                                            <picture>
                                                <source type="image/webp"
                                                        srcSet="/assets/avatar-CLimfe7W.webp 1x, /assets/avatar@2x-B_wrGpzH.webp 2x"/>
                                                <source type="image/jpg"
                                                        srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"/>
                                                <img
                                                    srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"
                                                    alt=""/>
                                            </picture>
                                        </div>
                                    </div>
                                    <div className="attorney__content">
                                        <div>
                                            <h3 className="attorney__name">Mountain, Joshua D.</h3>
                                            <div className="attorney__info">44 court hearings • Winning cases 27%</div>
                                        </div>
                                        <ul className="params__list">
                                            <li>Immigration Law</li>
                                            <li>Visa Applications</li>
                                            <li>Asylum</li>
                                            <li>Green Cards</li>
                                            <li>Citizenship</li>
                                            <li>DUI Defense</li>
                                        </ul>
                                    </div>
                                    <button className="button no-style">
                                        <img src="/assets/icon--delete-gray-BAOsHS3n.svg" alt="Delete"/>
                                    </button>
                                </li>
                            </ul>
                            <span>Add another attorney</span>
                            <label className="input-label input-attorney">
                                <input className="input" type="text" name=""/>
                                <span className="placeholder">Attorney ID</span>
                                <button className="button button-border">Add</button>
                            </label>
                            <button className="button button-black">Edit Profile</button>
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal" id="select-attorney">
                <div className="modal__overlay"></div>
                <div className="modal__info">
                    <img src="/assets/icon--info-black-B_CjCXpG.svg" alt=""/>
                    <p></p>
                    <button className="button no-style js--close-info">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt=""/>
                    </button>
                </div>
                <div className="modal__content">
                    <button className="button no-style close js--close-modal">
                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                    </button>
                    <div className="modal__form">
                        <form className="content content-l">
                            <h3>Select an attorney or add a new one</h3>
                            <span>Select attorneys previously added by you:</span>
                            <ul className="attorneys__list attorneys__list-with-select">
                                <li className="attorney">
                                    <label className="label-checkbox">
                                        <input type="checkbox"/>
                                        <div className="psevdocheckbox"></div>
                                    </label>
                                    <div className="attorney__avatar">
                                        <div className="avatar">
                                            <picture>
                                                <source type="image/webp"
                                                        srcSet="/assets/avatar-CLimfe7W.webp 1x, /assets/avatar@2x-B_wrGpzH.webp 2x"/>
                                                <source type="image/jpg"
                                                        srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"/>
                                                <img
                                                    srcSet="/assets/avatar-CvpKE8L_.jpg 1x, /assets/avatar@2x-BB4T-gub.jpg 2x"
                                                    alt=""/>
                                            </picture>
                                        </div>
                                    </div>
                                    <div className="attorney__content">
                                        <div>
                                            <h3 className="attorney__name">Mountain, Joshua D.</h3>
                                            <div className="attorney__info">44 court hearings • Winning cases 27%</div>
                                        </div>
                                        <ul className="params__list">
                                            <li>Immigration Law</li>
                                            <li>Visa Applications</li>
                                            <li>Asylum</li>
                                            <li>Green Cards</li>
                                            <li>Citizenship</li>
                                            <li>DUI Defense</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <span>or add new Attorney</span>
                            <button className="button button-border">Add Attorney</button>
                            <button className="button button-black">Add Attorneys</button>
                        </form>
                    </div>
                </div>
            </div>
            {/* Модальное окно подтверждения выхода */}
            {showLogoutModal && (
                <LogoutModal
                    show={showLogoutModal}
                    onClose={handleCloseLogoutModal}
                    onLogout={handleLogout}
                />
            )}
            {showCreateVendorPopup && (
                <CreateVendorProfile
                    show={showCreateVendorPopup}
                    onClose={() => setShowCreateVendorPopup(false)}
                    userId={user.id}
                />
            )}
            {/* Модальное окно для создания профиля юридической компании */}
            {showCreateLawCompanyPopup && (
                <CreateLawCompanyProfile
                    show={showCreateLawCompanyPopup}
                    onClose={handleCloseCreateLawCompanyPopup}
                    userId={user.id}
                />
            )}
            {isManageLegalCompaniesOpen && (
                <ManageLegalCompaniesPopup
                    show={isManageLegalCompaniesOpen}
                    closeManageLegalCompaniesPopup={closeManageLegalCompanies}
                />
            )}

            <footer>
                {/* Add footer content if applicable */}
            </footer>
        </div>
    );
};

export default UserProfile;
