// src/components/PushNotificationToggle.js

import React, { useState, useEffect, useCallback } from 'react';
import api from '../../../api'; // Убедитесь, что путь указан правильно

const PushNotificationToggle = () => {
    const [isSubscribed, setIsSubscribed] = useState(false); // Состояние подписки
    const [isLoading, setIsLoading] = useState(false); // Индикатор загрузки
    const [error, setError] = useState(null); // Сообщение об ошибке

    // Публичный VAPID ключ из .env
    const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;

    // Проверка подписки при монтировании компонента
    useEffect(() => {
        const checkSubscription = async () => {
            try {
                const response = await api.get('/user-push-subscription/'); // Обновленный эндпоинт
                setIsSubscribed(response.data.is_subscribed);
                console.log(`User is ${response.data.is_subscribed ? 'subscribed' : 'not subscribed'}.`);
            } catch (err) {
                console.error('Error checking push subscription:', err);
            }
        };
        checkSubscription();
    }, []);

    // Функция конвертации VAPID ключа из Base64 в Uint8Array
    const urlBase64ToUint8Array = (base64String) => {
        if (!base64String) {
            throw new Error('VAPID public key is missing.');
        }
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    // Функция получения CSRF токена из куки
    const getCSRFToken = () => {
        const name = 'csrftoken';
        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].split('=');
            if (cookie[0] === name) {
                return decodeURIComponent(cookie[1]);
            }
        }
        return null;
    };

    // Функция подписки пользователя на push-уведомления
    const subscribeUser = useCallback(async () => {
        // Проверка поддержки браузером
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
            alert('Push notifications are not supported by your browser.');
            return;
        }

        try {
            // Запрос разрешения на уведомления
            console.log('Requesting notification permission...');
            const permission = await Notification.requestPermission();
            console.log('Notification permission:', permission);
            if (permission !== 'granted') {
                alert('Notification permission was not granted.');
                return;
            }

            // Регистрация service worker
            console.log('Registering service worker...');
            const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`);
            console.log('Service Worker registered:', registration);

            // Ожидание готовности service worker
            const readyRegistration = await navigator.serviceWorker.ready;
            console.log('Service Worker ready:', readyRegistration);

            // Проверка наличия VAPID ключа
            if (!vapidPublicKey) {
                setError('VAPID public key is missing. Please check environment variables.');
                return;
            }

            // Конвертация VAPID ключа из Base64 в Uint8Array
            const applicationServerKey = urlBase64ToUint8Array(vapidPublicKey);
            console.log('Application Server Key:', applicationServerKey);

            // Подписка на push-уведомления
            const subscription = await readyRegistration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey,
            });

            console.log('User subscribed:', subscription);
            console.log('Full subscription object:', JSON.stringify(subscription.toJSON(), null, 2));

            // Получение объекта подписки в формате JSON
            const subscriptionJson = subscription.toJSON();
            console.log('Subscription keys:', subscriptionJson.keys);
            console.log('p256dh:', subscriptionJson.keys.p256dh);
            console.log('auth:', subscriptionJson.keys.auth);
            console.log('Type of p256dh:', typeof subscriptionJson.keys.p256dh);

            // Проверка наличия ключей и p256dh
            if (subscriptionJson?.keys?.p256dh && subscriptionJson.keys.auth) {
                console.log('Length of p256dh:', urlBase64ToUint8Array(subscriptionJson.keys.p256dh).length); // Should be 65
            } else {
                console.error('Missing keys or p256dh in subscription:', subscriptionJson);
                setError('Invalid subscription structure. Please try again.');
                return;
            }

            // Отправка подписки на сервер
            await api.post('/subscribe-push/', { subscription: subscriptionJson }, { // Sending JSON object
                headers: {
                    'X-CSRFToken': getCSRFToken(),
                },
                withCredentials: true, // Sending cookies for CSRF
            });
            console.log('Subscription request sent to server.');

            setIsSubscribed(true);
            console.log('Frontend state updated: isSubscribed = true');
            showNotification('Push notifications activated!', 'You will receive notifications from us.', 'success');
        } catch (err) {
            console.error('Failed to subscribe user:', err);
            setError('Subscription failed. Please try again.');
        }
    }, [vapidPublicKey]);

    // Функция отписки пользователя от push-уведомлений
    const unsubscribeUser = useCallback(async () => {
        try {
            console.log('Getting service worker registration for unsubscription...');
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.getSubscription();
            if (subscription) {
                console.log('Unsubscribing user...');
                const isUnsubscribed = await subscription.unsubscribe();
                console.log('User unsubscribed from push notifications:', isUnsubscribed);

                if (isUnsubscribed) {
                    // Отправка информации об отписке на сервер
                    await api.post('/unsubscribe-push/', { endpoint: subscription.endpoint }, {
                        headers: {
                            'X-CSRFToken': getCSRFToken(),
                        },
                        withCredentials: true, // Sending cookies for CSRF
                    });
                    console.log('Unsubscription request sent to server.');

                    setIsSubscribed(false);
                    console.log('Frontend state updated: isSubscribed = false');
                    showNotification('Unsubscribed from push notifications.', 'You will no longer receive notifications.', 'info');

                    // Попытка удалить service worker
                    console.log('Unregistering service worker...');
                    const swRegistration = await navigator.serviceWorker.getRegistration();
                    if (swRegistration) {
                        await swRegistration.unregister();
                        console.log('Service Worker unregistered.');
                    } else {
                        console.log('No Service Worker found to unregister.');
                    }

                    // Примечание: Невозможно программно изменить разрешение на уведомления.
                    // Пользователь должен самостоятельно изменить настройки браузера, если необходимо.
                } else {
                    console.error('Failed to unsubscribe from push notifications.');
                    setError('Unsubscription failed. Please try again.');
                }
            } else {
                console.log('No active subscriptions found for unsubscription.');
                setIsSubscribed(false);
            }
        } catch (err) {
            console.error('Error during unsubscription:', err);
            setError('Unsubscription failed. Please try again.');
        }
    }, []);

    // Обработчик переключения
    const handleToggle = async () => {
        setIsLoading(true);
        setError(null);
        if (isSubscribed) {
            await unsubscribeUser();
        } else {
            await subscribeUser();
        }
        setIsLoading(false);
    };

    // Функция отображения уведомлений (можно заменить на библиотеку, например, react-toastify)
    const showNotification = (title, message, type) => {
        // Реализуйте вашу логику отображения уведомлений здесь
        console.log(`${type.toUpperCase()}: ${title} - ${message}`);
        // Например, можно использовать alert или более продвинутую библиотеку уведомлений
    };

    return (
        <div className="toggle">
            <div className="billing-toggle-container">
                <div className="billing-toggle">
                    <div
                        className={`toggle ${isSubscribed ? 'checked' : ''}`}
                        onClick={!isLoading ? handleToggle : null}
                        role="switch"
                        aria-checked={isSubscribed}
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (!isLoading && (e.key === 'Enter' || e.key === ' ')) {
                                handleToggle();
                            }
                        }}
                        style={{
                            cursor: isLoading ? 'not-allowed' : 'pointer',
                            opacity: isLoading ? 0.5 : 1,
                        }}
                    >
                        <div className="toggle__container">
                            <div className="toggle__circle"></div>
                        </div>
                    </div>
                </div>
                {error && <span className="error-message">{error}</span>}
            </div>
        </div>
    );
};

// Утилита для конвертации VAPID ключа из Base64 в Uint8Array
function urlBase64ToUint8Array(base64String) {
    if (!base64String) {
        throw new Error('VAPID public key is missing.');
    }
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

// Утилита для получения CSRF токена из куки
function getCSRFToken() {
    const name = 'csrftoken';
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split('=');
        if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
        }
    }
    return null;
}

export default PushNotificationToggle;
