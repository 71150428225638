import React, {useState, useEffect, useCallback} from 'react';
import MultiSelectInColumnWithLabel from '../../components/MultiSelects/MultiSelectInColumnWithLabel';
import InputSelect from '../Form/Input/InputSelect';
import InputWithLabel from '../Form/Input/InputWithLabel';
import TextareaWithLabel from "../Form/Input/TextareaWithLabel";
import api from '../../api';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types'; // Для валидации пропсов

export default function AddAttorneyPopup({show, onClose, legalCompanyId, onAttorneyAdded, initialBarNumber}) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        licenseYear: '',
        specializations: [],
        registrationNumber: '', // Это поле будет заполнено initialBarNumber
        phoneNumber: '',
        description: '',
        languages: [],
        photo: null
    });

    const [errors, setErrors] = useState({});
    const [specializations, setSpecializations] = useState([]);
    const [uniqueLanguages, setUniqueLanguages] = useState([]);

    // Убираем дубликаты по имени языка
    const filterUniqueLanguages = useCallback((languages) => {
        const uniqueLanguages = [];
        const seenNames = new Set();

        languages.forEach(lang => {
            if (!seenNames.has(lang.name)) {
                seenNames.add(lang.name);
                uniqueLanguages.push(lang);
            }
        });

        return uniqueLanguages;
    }, []);


    // Запрос специализаций и языков при монтировании компонента или при изменении initialBarNumber
    useEffect(() => {
        const fetchSpecializationsAndLanguages = async () => {
            try {
                // Загружаем специализации
                const specializationsResponse = await api.get('/specializations-full/');
                console.log('Specializations data from API:', specializationsResponse.data);  // Логируем полученные данные
                setSpecializations(specializationsResponse.data.map(spec => ({
                    name: spec.name,
                    code: spec.specialization_id
                })));

                // Загружаем языки
                const languagesResponse = await api.get('/languages/');
                const uniqueLangs = filterUniqueLanguages(languagesResponse.data);
                console.log('Filtered unique languages:', uniqueLangs);  // Логируем уникальные языки
                setUniqueLanguages(uniqueLangs);  // Сохраняем уникальные языки
            } catch (error) {
                console.error('Error fetching specializations or languages:', error);
            }
        };

        fetchSpecializationsAndLanguages();
    }, [filterUniqueLanguages]);

    // Инициализация поля registrationNumber с initialBarNumber
    useEffect(() => {
        if (initialBarNumber) {
            setFormData(prevData => ({
                ...prevData,
                registrationNumber: initialBarNumber
            }));
        }
    }, [initialBarNumber]);

    const handleChange = useCallback((e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }, []);

    const handleFileChange = useCallback((e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            photo: file,
        }));
    }, []);

    const handleSpecializationChange = useCallback((selectedSpecializations) => {
        console.log('Selected specializations before filtering:', selectedSpecializations);  // Логируем специализации до обработки

        // Фильтруем специализации, чтобы убрать null значения
        const validSpecializations = selectedSpecializations
            .filter(spec => spec.code !== null)
            .map(spec => spec.code);

        console.log('Valid specializations after filtering:', validSpecializations);  // Логируем валидные специализации

        setFormData((prevData) => ({
            ...prevData,
            specializations: validSpecializations,
        }));
    }, []);


    const handleLanguageChange = useCallback((index, e) => {
        const {value} = e.target;

        // Находим объект языка по названию
        const selectedLanguage = uniqueLanguages.find(lang => lang.name === value);

        setFormData((prevData) => {
            const updatedLanguages = prevData.languages.map((language, i) => {
                if (i === index) {
                    return {
                        ...language,
                        name: value,
                        language_id: selectedLanguage ? selectedLanguage.language_id : ''
                    };
                }
                return language;
            });
            console.log('Updated form languages after selection:', updatedLanguages);  // Логируем выбранные языки
            return {
                ...prevData,
                languages: updatedLanguages,
            };
        });
    }, [uniqueLanguages]);

    const handleAddLanguageSelect = useCallback(() => {
        setFormData((prevData) => ({
            ...prevData,
            languages: [...prevData.languages, {language_id: '', name: '', proficiency_level: 'Fluent'}]
        }));
    }, []);

    const handleDeleteLanguage = useCallback((index) => {
        const updatedLanguages = formData.languages.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            languages: updatedLanguages,
        }));
    }, [formData.languages]);

    const validateForm = useCallback(() => {
        const newErrors = {};

        if (!formData.firstName) {
            newErrors.firstName = 'First name is required';
        }

        if (!formData.lastName) {
            newErrors.lastName = 'Last name is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        }

        if (!formData.licenseYear) {
            newErrors.licenseYear = 'License year is required';
        }

        if (!formData.registrationNumber) {
            newErrors.registrationNumber = 'Registration number is required';
        }

        if (!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        }

        if (formData.description.length < 300) {
            newErrors.description = 'Description must be at least 300 characters';
        }

        if (!formData.languages.every(lang => lang.language_id)) {
            newErrors.languages = 'Each language must have a valid ID.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            console.log('Validation errors:', newErrors);  // Выводим ошибки в консоль
        }

        return Object.keys(newErrors).length === 0;
    }, [formData]);

    const getRequestConfig = () => {
        const token = localStorage.getItem('authToken');
        const csrfToken = Cookies.get('csrftoken');
        return {
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-CSRFToken': csrfToken,  // Оставляем CSRF токен
            },
            withCredentials: true,
        };
    };

    const handleAddAttorney = async (e) => {
        e.preventDefault();
        console.log("handleAddAttorney called"); // Убедитесь, что функция вызывается

        if (!validateForm()) {
            console.log("Validation failed"); // Проверьте, сработала ли валидация
            return;
        }

        console.log("Validation passed"); // Валидация прошла

        if (legalCompanyId) {
            // Если legalCompanyId присутствует, отправляем данные на сервер для добавления к юридической компании
            const formDataToSend = new FormData();
            formDataToSend.append('first_name', formData.firstName);
            formDataToSend.append('last_name', formData.lastName);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('license_year', formData.licenseYear);
            formDataToSend.append('registration_number', formData.registrationNumber);
            formDataToSend.append('phone_number', formData.phoneNumber);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('specializations', JSON.stringify(formData.specializations));
            formDataToSend.append('languages', JSON.stringify(formData.languages.map(lang => lang.language_id)));

            if (formData.photo) {
                formDataToSend.append('photo', formData.photo);
            }

            console.log([...formDataToSend.entries()]); // Логируем данные для проверки

            try {
                const config = getRequestConfig();
                const response = await api.post(`/legal-companies/${legalCompanyId}/attorneys/`, formDataToSend, config);

                if (response.status === 201 || response.status === 200) { // 200 OK также может быть возвращен
                    console.log("Attorney added successfully"); // Проверяем успешный ответ
                    // Предполагаем, что API возвращает данные нового адвоката
                    onAttorneyAdded(response.data); // Передаем данные нового адвоката родителю
                    onClose();
                } else {
                    console.error('Error adding attorney:', response);
                    setErrors({general: 'Error adding attorney. Please try again.'});
                }
            } catch (error) {
                console.error('Error adding attorney:', error.response ? error.response.data : error.message);
                setErrors({general: 'Error adding attorney. Please try again.'});
            }
        } else {
            // Если legalCompanyId отсутствует, отправляем данные на другой API-эндпоинт для самостоятельного создания адвоката
            const formDataToSend = new FormData();
            formDataToSend.append('first_name', formData.firstName);
            formDataToSend.append('last_name', formData.lastName);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('license_year', formData.licenseYear);
            formDataToSend.append('registration_number', formData.registrationNumber);
            formDataToSend.append('phone_number', formData.phoneNumber);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('specializations', JSON.stringify(formData.specializations));
            formDataToSend.append('languages', JSON.stringify(formData.languages.map(lang => lang.language_id)));

            if (formData.photo) {
                formDataToSend.append('photo', formData.photo);
            }

            console.log([...formDataToSend.entries()]); // Логируем данные для проверки

            try {
                const config = getRequestConfig();
                const response = await api.post('/attorneys/create/', formDataToSend, config); // Новый эндпоинт для создания адвоката

                if (response.status === 201 || response.status === 200) { // 200 OK также может быть возвращен
                    console.log("Attorney added successfully"); // Проверяем успешный ответ
                    // Предполагаем, что API возвращает данные нового адвоката
                    onAttorneyAdded(response.data); // Передаем данные нового адвоката родителю
                    onClose();
                } else {
                    console.error('Error adding attorney:', response);
                    setErrors({general: 'Error adding attorney. Please try again.'});
                }
            } catch (error) {
                console.error('Error adding attorney:', error.response ? error.response.data : error.message);
                setErrors({general: 'Error adding attorney. Please try again.'});
            }
        }
    };


    if (!show) return null;

    return (
        <div className="modal visible">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button onClick={onClose} className="button no-style close js--close-modal">
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <form className="content" onSubmit={handleAddAttorney}>
                        <h2>Add New Attorney</h2>

                        {/* Общие ошибки */}
                        {errors.general && (
                            <div className="error-message">
                                {errors.general}
                            </div>
                        )}

                        <div className="profile__logo">
                            <picture>
                                <img
                                    src={formData.photo ? URL.createObjectURL(formData.photo) : '/assets/logo--img-Bjfxg2Ti2.svg'}
                                    alt="Attorney Photo"
                                />
                            </picture>
                            <label className="userpick__edit">
                                <img src="/assets/icon--edit-bg-BVfPEI-f.svg" alt="Edit"/>
                                <input type="file" name="photo" onChange={handleFileChange}/>
                            </label>
                        </div>

                        <InputWithLabel
                            name="firstName"
                            label="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            error={errors.firstName}
                        />
                        <InputWithLabel
                            name="lastName"
                            label="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            error={errors.lastName}
                        />
                        <InputWithLabel
                            name="phoneNumber"
                            label="Phone Number"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            error={errors.phoneNumber}
                        />
                        <InputWithLabel
                            name="email"
                            label="Email"
                            value={formData.email}
                            onChange={handleChange}
                            error={errors.email}
                        />
                        <InputWithLabel
                            name="licenseYear"
                            label="License Year"
                            value={formData.licenseYear}
                            onChange={handleChange}
                            type="number"
                            error={errors.licenseYear}
                        />
                        <InputWithLabel
                            name="registrationNumber"
                            label="Registration Number"
                            value={formData.registrationNumber}
                            onChange={handleChange}
                            error={errors.registrationNumber}
                        />
                        <TextareaWithLabel
                            label='Description'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            error={errors.description}
                        />
                        <div className="form-group">
                            <label className="form-label">Specializations</label>
                            <div className="form-input">
                                <MultiSelectInColumnWithLabel
                                    options={specializations}
                                    onChange={handleSpecializationChange}
                                    placeholder="Select specializations"
                                />
                            </div>
                        </div>

                        {/*<label className="form-label">Languages</label>*/}

                        {/*{formData.languages.map((language, index) => (*/}
                        {/*    <React.Fragment key={index}>*/}
                        {/*        <InputSelect*/}
                        {/*            name="name"*/}
                        {/*            value={language.name}*/}
                        {/*            onChange={(e) => handleLanguageChange(index, e)}*/}
                        {/*            label="Language"*/}
                        {/*            options={uniqueLanguages.map((lang) => ({*/}
                        {/*                value: lang.name,*/}
                        {/*                label: lang.name,*/}
                        {/*            }))}*/}
                        {/*            showDelete={true}*/}
                        {/*            onDelete={() => handleDeleteLanguage(index)}*/}
                        {/*        />*/}
                        {/*    </React.Fragment>*/}
                        {/*))}*/}

                        {/*<button*/}
                        {/*    className="underline no-style"*/}
                        {/*    type="button"*/}
                        {/*    onClick={handleAddLanguageSelect}*/}
                        {/*>*/}
                        {/*    Add New Language*/}
                        {/*</button>*/}

                        <button type="submit" className="button button-black">
                            Add Attorney
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

// Валидация пропсов
AddAttorneyPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    legalCompanyId: PropTypes.string.isRequired,
    onAttorneyAdded: PropTypes.func.isRequired,
    initialBarNumber: PropTypes.string, // Новый проп для передачи номера адвоката
};

// Значение по умолчанию для initialBarNumber
AddAttorneyPopup.defaultProps = {
    initialBarNumber: '',
};
