// src/components/User/ResendActivationEmail.js

import React, { useState } from 'react';
import { useNotification } from '../../context/NotificationContext';
import api from '../../api';

const ResendActivationEmail = () => {
    const [email, setEmail] = useState('');
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post('/resend-activation-email/', { email });
            if (response.status === 200) {
                showNotification(
                    'Email Sent',
                    'A confirmation email has been resent. Please check your inbox.',
                    null,
                    'success'
                );
            }
        } catch (error) {
            console.log('Ошибка отправки письма подтверждения:', error.response);
            showNotification(
                'Error',
                error.response?.data?.error || 'Failed to resend the confirmation email.',
                null,
                'error'
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="resend-activation-container">
            <h2>Resend Confirmation Email</h2>
            <form onSubmit={handleSubmit} className="resend-activation-form">
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                        placeholder="Your Email"
                        className="form-control"
                    />
                </div>
                <button type="submit" disabled={loading} className="btn btn-primary">
                    {loading ? 'Sending...' : 'Resend Confirmation'}
                </button>
            </form>
        </div>
    );
};

export default ResendActivationEmail;
