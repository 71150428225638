// src/components/User/SignIn.js

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import InputWithLabel from "../Form/Input/InputWithLabel";
import api from '../../api';
import { useNotification } from '../../context/NotificationContext'; // Импортируем хук уведомлений
import { useUser } from '../../context/UserContext'; // Импортируем хук контекста пользователя
import './SignIn.css'; // Добавьте соответствующий CSS

const SignIn = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { showNotification } = useNotification(); // Получаем только функцию showNotification
    const { setAuthTokens } = useUser(); // Получаем функцию setAuthTokens из контекста пользователя

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            navigate('/profile'); // Redirect to profile if user is authenticated
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setLoading(true);

        // Валидация полей формы
        if (!formData.email || !formData.password) {
            const newErrors = {
                email: !formData.email ? 'Email is required.' : '',
                password: !formData.password ? 'Password is required.' : ''
            };
            setErrors(newErrors);

            showNotification(
                'Please fill in all required fields.',
                'Make sure to check the correctness of the entered data.',
                null,
                'error'
            );

            setLoading(false);
            return;
        }

        try {
            console.log('Sending data:', {
                username: formData.email,
                password: formData.password
            });

            const response = await api.post('/token/', {
                username: formData.email,
                password: formData.password
            });

            if (response.status === 200) {
                // Сохранение токенов в localStorage
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                // Отображение уведомления об успешном входе
                showNotification(
                    'Login successful.',
                    'Welcome back!',
                    null,
                    'success'
                );

                // Установка токенов в контекст пользователя
                setAuthTokens({
                    access: response.data.access,
                    refresh: response.data.refresh
                });

                // Перенаправление на страницу профиля
                navigate('/profile');
                // window.location.reload();
            } else {
                setErrors({
                    email: response.data.detail || 'Invalid credentials.',
                    password: ' '
                });

                // Отображение уведомления о неверных учетных данных
                showNotification(
                    'Invalid credentials. Please try again.',
                    'Check the entered information and try again.',
                    null,
                    'error'
                );
            }
        } catch (error) {
            console.log('Error response:', error.response);

            if (error.response && error.response.data) {
                setErrors({
                    email: error.response.data.detail || 'Something went wrong. Please try again later.',
                    password: ' '
                });

                showNotification(
                    error.response.data.detail || 'Login error. Please try again later.',
                    'Check your entered information or try again later.',
                    null,
                    'error'
                );
            } else {
                setErrors({ email: 'Something went wrong. Please try again later.' });

                showNotification(
                    'Network error. Please check your connection and try again.',
                    'Ensure you have an active internet connection and try again.',
                    null,
                    'error'
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-form-container">
            <div
                className="main-form__img"
                style={{
                    backgroundColor: '#fff',
                    backgroundImage: "url('/assets/sign-in-image.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 50%',
                    backgroundSize: 'cover',
                }}
            ></div>

            <div className="main-form__right">
                <form className="main-form__content" onSubmit={handleSubmit}>
                    <div className="scroll-wrap">
                        <h2>Let's get started</h2>
                        <div className="sign-up-link">
                            <span>Don't have an account? </span>
                            <Link to="/sign-up">Sign Up</Link>
                        </div>

                        <InputWithLabel
                            label='E-mail'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            type='email'
                            hasError={!!errors.email} // Добавляем пропс для указания ошибки
                        />
                        <InputWithLabel
                            label='Password'
                            name='password'
                            value={formData.password}
                            onChange={handleChange}
                            type='password'
                            hasError={!!errors.password} // Добавляем пропс для указания ошибки
                        />
                        <div
                            className="forgot-password-link"
                            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '15px' }}
                        >
                            <Link to="/forgot-password">Forgot Password?</Link>
                        </div>

                        <button className="button button-black" type="submit" disabled={loading}>
                            {loading ? 'Loading...' : 'Continue'}
                        </button>
                        <button className="button button-border">
                            <img src="/assets/Apple.svg" alt="Apple Icon" style={{ marginRight: '8px' }} />
                            Continue with Apple
                        </button>
                        <button className="button button-border">
                            <img src="/assets/Google.svg" alt="Google Icon" style={{ marginRight: '8px' }} />
                            Continue with Google
                        </button>

                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignIn;
