import React from 'react';

const LogoutModal = ({ show, onClose, onLogout }) => {
    if (!show) return null;

    return (
        <div className="modal visible">
            <div className="modal__overlay" onClick={onClose}></div>
            <div className="modal__content">
                <button className="button no-style close" onClick={onClose}>
                    <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                </button>
                <div className="modal__form">
                    <div className="content">
                        <h2> Logout</h2>
                        <span className="subtitle">Do you really want to log out?</span>
                        <div className="buttons-group">
                            <button className="button button-border" onClick={onLogout}>Yes</button>
                            <button className="button button-black" onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogoutModal;
