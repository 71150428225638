import React, {useState, useEffect, useRef} from 'react';
import styles from './GetPro.module.css';
import api from '../../api';
import {useNotification} from '../../context/NotificationContext'; // Импортируем хук уведомлений
import ManageLegalCompaniesPopup from './ManageLegalCompaniesPopup'; // Убедитесь, что путь правильный

const GetPro = ({ openManageLegalCompanies }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState(null);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [isPlayingMain, setIsPlayingMain] = useState(false);
    const [isPlayingLightbox, setIsPlayingLightbox] = useState(false);
    const {showNotification} = useNotification(); // Получаем функцию showNotification из контекста
    const [isManageLegalCompaniesOpen, setIsManageLegalCompaniesOpen] = useState(false);
    const videoRefMain = useRef(null);
    const videoRefLightbox = useRef(null);

    // Обработчик клика по иконке информации
    const handleInfoClick = (comparison) => {
        showNotification(
            'Feature Information',
            `${comparison.feature}\n: ${comparison.description}`,
            null,
            'info' // Тип уведомления (например, 'success', 'error', 'info', 'warning')
        );
    };

    // Обработчик клика по вкладке
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // Открыть лайтбокс
    const openLightbox = () => {
        setIsLightboxOpen(true);
    };

    // Закрыть лайтбокс
    const closeLightbox = () => {
        setIsLightboxOpen(false);
        setIsPlayingLightbox(false);
        if (videoRefLightbox.current) {
            videoRefLightbox.current.pause();
            videoRefLightbox.current.currentTime = 0;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/getpro/');
                setData(response.data);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        // Когда переключаемся на другую вкладку, сбрасываем состояние воспроизведения основного видео
        if (activeTab !== 0) {
            setIsPlayingMain(false);
            if (videoRefMain.current) {
                videoRefMain.current.pause();
                videoRefMain.current.currentTime = 0;
            }
        }
    }, [activeTab]);

    if (!data) {
        return <div>Loading...</div>;
    }

    // Формируем полный URL для видео и постера
    const videoUrl = `${process.env.REACT_APP_BASE_URL}${data.videoFile}`;
    const posterUrl = data.posterImage
        ? `${process.env.REACT_APP_BASE_URL}${data.posterImage}`
        : null;

    // Функция для обработки нажатия на кнопку воспроизведения основного видео
    const handlePlayButtonClickMain = () => {
        if (videoRefMain.current) {
            videoRefMain.current.play();
            setIsPlayingMain(true);
        }
    };

    // Функция для обработки нажатия на кнопку воспроизведения в лайтбоксе
    const handlePlayButtonClickLightbox = () => {
        if (videoRefLightbox.current) {
            videoRefLightbox.current.play();
            setIsPlayingLightbox(true);
        }
    };

    // Обработчик события воспроизведения основного видео
    const handleVideoPlayMain = () => {
        setIsPlayingMain(true);
    };

    // Обработчик события паузы основного видео
    const handleVideoPauseMain = () => {
        setIsPlayingMain(false);
    };

    // Обработчик события воспроизведения видео в лайтбоксе
    const handleVideoPlayLightbox = () => {
        setIsPlayingLightbox(true);
    };

    // Обработчик события паузы видео в лайтбоксе
    const handleVideoPauseLightbox = () => {
        setIsPlayingLightbox(false);
    };


    return (
        <>

            <div className={`${styles.profileGetPro} profile__block`}>
                <div className="container">
                    <div className="col-2">
                        <div className={styles.getproInfo}>
                            <h3>{data.title}</h3>
                            <p>{data.description}</p>
                            <div className={styles.buttonContainer}>
                                <button className="button button-border" onClick={openManageLegalCompanies}>
                                    {data.buttonName}
                                </button>
                                {/* Кнопка для открытия лайтбокса с иконкой Explainer */}
                                <button className="button button-explainer" onClick={openLightbox}>
                                    Explainer
                                </button>
                            </div>
                        </div>
                        <div className={styles.getproTable}>
                            <div className="tabs">
                                <ul className="tabs__caption" style={{padding: '0 0', marginBottom: '10px'}}>
                                    <li
                                        className={activeTab === 0 ? 'active' : ''}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        Explainer
                                    </li>
                                    <li
                                        className={activeTab === 1 ? 'active' : ''}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        Comparison
                                    </li>
                                </ul>
                                <div>
                                    {activeTab === 0 && (
                                        <div className="tabs__content active" style={{position: 'relative'}}>
                                            {/* Видео с постером */}
                                            <video
                                                width="100%"
                                                ref={videoRefMain}
                                                onPlay={handleVideoPlayMain}
                                                onPause={handleVideoPauseMain}
                                                poster={posterUrl || undefined} // Добавляем постер
                                            >
                                                <source src={videoUrl} type="video/mp4"/>
                                                Your browser does not support the video tag.
                                            </video>
                                            {/* Кнопка воспроизведения поверх видео */}
                                            {!isPlayingMain && (
                                                <div
                                                    className={styles.playButton}
                                                    onClick={handlePlayButtonClickMain}
                                                >
                                                    &#9658;
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {activeTab === 1 && (
                                        <div className="tabs__content active">
                                            <table className={styles.vendorTable}>
                                                <tbody>
                                                <tr>
                                                    <td>Comparison of two tariff types</td>
                                                    <td>Free</td>
                                                    <td>Pro Account</td>
                                                </tr>
                                                {data.comparisons.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className={styles.featureCell}>
                                                            {/* Иконка информации */}
                                                            <svg
                                                                onClick={() => handleInfoClick(item)} // Передаем весь объект comparison
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className={styles.icon}
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M2.5 7.34884C2.5 4.39476 4.89476 2 7.84884 2H17.1512C20.1052 2 22.5 4.39476 22.5 7.34884V16.6512C22.5 19.6052 20.1052 22 17.1512 22H7.84884C4.89476 22 2.5 19.6052 2.5 16.6512V7.34884ZM13.6615 11.0697C13.6615 10.6843 13.3492 10.372 12.9639 10.372L11.1034 10.372C10.7181 10.372 10.4057 10.6843 10.4057 11.0697C10.4057 11.455 10.7181 11.7673 11.1034 11.7673H12.2662L12.2662 16.6511C12.2662 17.0364 12.5786 17.3487 12.9639 17.3487C13.3492 17.3487 13.6615 17.0364 13.6615 16.6511V11.0697ZM13.6617 7.34896C13.6617 6.96364 13.3493 6.65128 12.964 6.65128C12.5787 6.65128 12.2663 6.96364 12.2663 7.34896V8.27919C12.2663 8.66451 12.5787 8.97687 12.964 8.97686C13.3493 8.97686 13.6617 8.66451 13.6617 8.27919V7.34896Z"
                                                                    fill="#B3C4DB"
                                                                />
                                                            </svg>
                                                            {item.feature}
                                                        </td>
                                                        <td>
                                                            {item.free === '+' ? (
                                                                <img
                                                                    src="/assets/icon--ok-D5h7wddo.svg"
                                                                    alt="OK"
                                                                />
                                                            ) : (
                                                                item.free
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item.pro === '+' ? (
                                                                <img
                                                                    src="/assets/icon--ok-D5h7wddo.svg"
                                                                    alt="OK"
                                                                />
                                                            ) : (
                                                                item.pro
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {isLightboxOpen && (
                            <div className={styles.lightbox} onClick={closeLightbox}>
                                <div className={styles.lightboxContent} onClick={(e) => e.stopPropagation()}>
                                    {/* Видео внутри лайтбокса */}
                                    <div style={{position: 'relative'}}>
                                        <video
                                            width="100%"
                                            ref={videoRefLightbox}
                                            controls
                                            onPlay={handleVideoPlayLightbox}
                                            onPause={handleVideoPauseLightbox}
                                            poster={posterUrl || undefined} // Добавляем постер
                                        >
                                            <source src={videoUrl} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        {/* Кнопка воспроизведения поверх видео в лайтбоксе */}
                                        {!isPlayingLightbox && (
                                            <div
                                                className={styles.playButton}
                                                onClick={handlePlayButtonClickLightbox}
                                            >
                                                &#9658;
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        onClick={closeLightbox}
                                        className="button no-style close js--close-modal"
                                        style={{position: 'absolute', top: '10px', right: '10px'}}
                                    >
                                        <img src="/assets/icon--close-CN6QlgAA.svg" alt="Close"/>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
};

export default GetPro;
