// Импортируйте необходимые методы из GSAP
import { gsap } from 'gsap';

// Импортируйте класс TextSplitter
import { TextSplitter } from './textSplitter.js';

// Определите символ, используемый в анимации
const blackSquare = '■';

// Определите класс для создания эффектов при наведении на текст
export class TextAnimator {
    constructor(textElement) {
        if (!textElement || !(textElement instanceof HTMLElement)) {
            throw new Error('Invalid text element provided.');
        }

        this.textElement = textElement;
        this.splitText();
    }

    splitText() {
        this.splitter = new TextSplitter(this.textElement, {
            splitTypes: 'words, chars' // Исправлено на 'splitTypes'
        });

        this.originalChars = this.splitter.getChars().map(char => char.innerHTML);
    }

    animate() {
        this.reset(); // Останавливаем предыдущую анимацию перед началом новой

        const chars = this.splitter.getChars();

        chars.forEach((char, position) => {
            let initialHTML = char.innerHTML;
            let repeatCount = 0;

            gsap.fromTo(char,
                { opacity: 0 },
                {
                    duration: 0.03,
                    onStart: () => {
                        gsap.set(char, { '--opa': 1 });
                    },
                    onComplete: () => {
                        gsap.set(char, { innerHTML: initialHTML, delay: 0.03 });
                    },
                    repeat: 0,
                    onRepeat: () => {
                        repeatCount++;
                        if (repeatCount === 1) {
                            gsap.set(char, { '--opa': 0 });
                        }
                    },
                    repeatRefresh: true,
                    repeatDelay: 0.03,
                    delay: (position + 1) * 0.07,
                    innerHTML: () => blackSquare,
                    opacity: 1
                }
            );
        });
    }

    reset() {
        const chars = this.splitter.getChars();
        chars.forEach((char, index) => {
            gsap.killTweensOf(char);
            char.innerHTML = this.originalChars[index];
        });
    }
}
